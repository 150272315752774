footer {
    background-color: $color_footer;
    font-size: 16px;

    @media(max-width: $mobile) {
        display: block;
        text-align: center;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 100px;

        @media(max-width: $portrait_large) {
            padding: 30px 50px;
        }

        @media(max-width: $mobile) {
            display: block;
        }
    }

    .item {
        display: inline-flex;
        align-items: center;

        @media(max-width: $portrait_large) {
            display: block;
        }

        [data-component="text"] {
            color: $color_white;

            p {
                font-size: 1em;
                margin-bottom: 0;
            }
        }
    }

    .footer {

        &-logo {
            display: flex;

            img {
                max-width: 117px;
            }

            @media(max-width: $portrait_large) {
                margin-bottom: 2rem;
            }

            @media(max-width: $mobile) {
                width: 125px;
                margin: 0 auto 1.5rem;
                display: block;
                text-align: center;
            }


            &:after {
                content: "";
                display: block;
                width: 2px;
                background-color: $color_white;
                margin: 0 2.5rem;

                @media(max-width: $portrait_large) {
                    display: none;
                }
            }
        }

        &-social {
            display: flex;
            align-items: center;
            margin: 0 -1rem;



            > * {
                margin: 0 1rem;
                transition: .3s;

                &:hover {
                    opacity: .75;
                }
            }

            @media(max-width: $mobile) {
                margin: 2rem 0;
                justify-content: center;
            }
        }
    }
}
