[data-template="aboutus-section-3"] {
    margin: 10px 0;
    font-size: 16px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(to bottom, rgba(white,.5) , rgba(white,.5)),url(../img/home-section-4.png);
    text-align: center;

    .container {
        padding: 10rem 5rem 10rem;

        @media(max-width: $mobile) {
            padding: 5rem 3rem;
        }
    }

    .inner {
        max-width: 350px;
        margin: 0 auto;

        [data-component="text"] {
            b {
                font-weight: 700;
            }

            @media(max-width: $mobile) {
                p {
                    font-size: 20px;
                }
            }

            @media(max-width: 320px) {
                p {
                    font-size: 1em;
                }
            }
        }
    }

    .title {
        font-weight: 700;
        color: $color_text;
        font-size: 3em;
        text-transform: uppercase;

        @media(max-width: $mobile) {
            font-size: 24px;
        }

        &:after {
            content: "";
            display: block;
            margin: 2.5rem auto;
            height: 8px;
            width: 64px;
            background-color: $color_yellow;
        }
    }
}
