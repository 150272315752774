*, *:before, *:after {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

html {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;

    @media(max-width: $laptop_small) {
        font-size: 80%
    }
    @media(max-width: $portrait_large) {
        font-size: 73%
    }
}

body {
    overflow-x: hidden;
    background-color: $color_white;
    position: relative;
    padding-top: 80px;

    @media(max-width: $laptop_small) {
        padding-top: 5rem;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
}

%scroll {
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color_text;
    }

    &::-webkit-scrollbar-track {
        background: #c0c0c0;
    }
}


h1, h2, h3, h4 {
    text-transform: uppercase;
    font-size: 3em;
    margin-bottom: 1.688rem;
    line-height: 1.1;
}