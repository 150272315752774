[data-component="banners"] {

    @media(max-width: $portrait_large) {
        display: flex;
        margin: 6rem -10px 3rem;

        > * {
            margin: 0 10px;
        }
    }

    @media(max-width: $mobile_large) {
        display: block;
        margin: 5rem 0 0 0;

        > * {
            margin: 10px 0;
        }
    }

    .figure,
    figure {
        display: block;
        margin-bottom: 3.375rem;
        position: relative;
        text-decoration: none;
        background: black;
        height: 410px;
        width: 100%;

        [data-component="text"] {
            color: $color_white;
            line-height: 1.15;

            p {
                font-size: 16px;
            }

            h3 {
                text-transform: uppercase;
                font-size: 24px;
                font-weight: 700;
            }
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }

        .widget-bottom {
            position: absolute;
            left: 30px;
            bottom: 40px;
        }

        .text {
            &-top, &-bottom {
                position: absolute;
                max-width: 100%;

            }

            &-top {
                top: 40px;
                left: 30px;
                width: 80%;
            }

            &-bottom {
                bottom: 124px;
                left: 30px;
                width: 132px;
            }
        }
        @media(max-width: $portrait_large) {
            [data-component="button"] {
                .btn {
                    padding: 20px;
                }
            }
        }

        @media(max-width: $mobile_large) {
            height: 425px;
        }
        @media(max-width: $mobile) {
            margin: 10px 0 40px;
        }
    }
}
