[data-template="navigation"] {
    background-color: $color_white;
    box-shadow: 0 10px 20px -14px rgba(0,0,0,.3);
    font-size: 16px;
    // position: relative;
    z-index: 10;

    position: fixed;
    top: 0; left: 0;
    width: 100%;

    .container {
        display: flex;
        align-items: center;
        height: 80px;
        padding: 0 2.063rem;
    }

    @media(max-width: 1299px) {
        height: 80px;
        padding: 2rem;
        overflow: hidden;
        transition: .3s;
        font-size: 24px;

        &.open {
            height: 100vh;

            .navbutton {
                span {
                    &:nth-child(1) {
                        top: 10px;
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }

                    &:nth-child(4) {
                        top: 10px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }

        .container {
            display: block;
            height: auto;
        }
    }

    .navmobile {
        position: absolute;
        top: 28px;
        right: 30px;
        display: none;

        @media(max-width: 1299px) {
            display: block
        }

        &:before {
            position: absolute;
            left: -70px;
            top: -5px;
            content: "";
            width: 35px; height: 35px;
            background-image: url('../img/svg/search.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;

        }
    }

    .navbutton {
        width: 40px;
        height: 30px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        display: block;



        span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: $color_text;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2), &:nth-child(3) {
                top: 12px;
            }

            &:nth-child(4) {
                top: 24px;
            }
        }
    }
}
