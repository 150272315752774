@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.grid.lefty {
  display: flex;
  border-top: 2px solid #F5F5F5;
  margin-top: 2rem;
  margin-bottom: 7rem;
  padding-top: 3.5rem;
  position: relative; }
  .grid.lefty .left {
    min-width: 280px;
    padding-right: 38px;
    padding-left: 15px;
    margin-right: 46px;
    border-right: 3px solid #F5F5F5; }
    .grid.lefty .left > *:last-child {
      margin-bottom: 0;
      padding: 0;
      border-bottom: none; }
  .grid.lefty .right {
    padding-left: 20px;
    width: 100%; }

.grid.aside {
  display: flex;
  border-top: 2px solid #F5F5F5;
  margin-top: 2rem;
  padding-top: 3.5rem; }
  .grid.aside .left {
    width: 73%;
    margin-right: 4rem; }
  .grid.aside .right {
    width: 27%; }
  @media (max-width: 768px) {
    .grid.aside {
      display: block;
      margin-top: 0;
      padding-top: 0; }
      .grid.aside .left, .grid.aside .right {
        width: 100%; } }
  @media (max-width: 667px) {
    .grid.aside {
      border-top: none; } }

.grid.col-2 {
  display: flex; }
  .grid.col-2 > .item {
    width: 50%;
    flex-basis: auto; }
    .grid.col-2 > .item:first-of-type {
      margin-right: 5rem; }
      @media (max-width: 768px) {
        .grid.col-2 > .item:first-of-type {
          margin-bottom: 7rem; } }
  @media (max-width: 1024px) {
    .grid.col-2.table > .item:first-of-type {
      margin-right: 2rem; } }
  @media (max-width: 768px) {
    .grid.col-2 {
      display: block;
      width: 100%; }
      .grid.col-2 > .item {
        width: 100%; }
      .grid.col-2.table {
        display: block;
        width: 100%; }
        .grid.col-2.table > .item {
          margin: 0 0 3rem;
          width: 100%; }
          .grid.col-2.table > .item.mobile-disabled {
            display: none; } }

*, *:before, *:after {
  box-sizing: border-box; }

html, body {
  height: 100%; }

html {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased; }
  @media (max-width: 1024px) {
    html {
      font-size: 80%; } }
  @media (max-width: 768px) {
    html {
      font-size: 73%; } }

body {
  overflow-x: hidden;
  background-color: #FFFFFF;
  position: relative;
  padding-top: 80px; }
  @media (max-width: 1024px) {
    body {
      padding-top: 5rem; } }

a {
  color: inherit;
  text-decoration: none; }

.container {
  max-width: 1440px;
  margin: 0 auto; }

[data-template="home-section-4"] .list::-webkit-scrollbar {
  width: 4px; }

[data-template="home-section-4"] .list::-webkit-scrollbar-thumb {
  background: #312D3B; }

[data-template="home-section-4"] .list::-webkit-scrollbar-track {
  background: #c0c0c0; }

h1, h2, h3, h4 {
  text-transform: uppercase;
  font-size: 3em;
  margin-bottom: 1.688rem;
  line-height: 1.1; }

[data-template="navigation"] {
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px -14px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }
  [data-template="navigation"] .container {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 2.063rem; }
  @media (max-width: 1299px) {
    [data-template="navigation"] {
      height: 80px;
      padding: 2rem;
      overflow: hidden;
      transition: .3s;
      font-size: 24px; }
      [data-template="navigation"].open {
        height: 100vh; }
        [data-template="navigation"].open .navbutton span:nth-child(1) {
          top: 10px;
          width: 0%;
          left: 50%; }
        [data-template="navigation"].open .navbutton span:nth-child(2) {
          transform: rotate(45deg); }
        [data-template="navigation"].open .navbutton span:nth-child(3) {
          transform: rotate(-45deg); }
        [data-template="navigation"].open .navbutton span:nth-child(4) {
          top: 10px;
          width: 0%;
          left: 50%; }
      [data-template="navigation"] .container {
        display: block;
        height: auto; } }
  [data-template="navigation"] .navmobile {
    position: absolute;
    top: 28px;
    right: 30px;
    display: none; }
    @media (max-width: 1299px) {
      [data-template="navigation"] .navmobile {
        display: block; } }
    [data-template="navigation"] .navmobile:before {
      position: absolute;
      left: -70px;
      top: -5px;
      content: "";
      width: 35px;
      height: 35px;
      background-image: url("../img/svg/search.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
  [data-template="navigation"] .navbutton {
    width: 40px;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    display: block; }
    [data-template="navigation"] .navbutton span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: #312D3B;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out; }
      [data-template="navigation"] .navbutton span:nth-child(1) {
        top: 0px; }
      [data-template="navigation"] .navbutton span:nth-child(2), [data-template="navigation"] .navbutton span:nth-child(3) {
        top: 12px; }
      [data-template="navigation"] .navbutton span:nth-child(4) {
        top: 24px; }

[data-template="home-section-1"] {
  position: relative;
  overflow: hidden; }
  [data-template="home-section-1"]:after {
    content: "";
    display: block;
    background-color: #ffc600;
    position: absolute;
    right: -11%;
    top: 0;
    width: 60%;
    height: 200%;
    transform: skewx(-7deg);
    z-index: -1; }
    @media (max-width: 1024px) {
      [data-template="home-section-1"]:after {
        right: -6%; } }
  @media (max-width: 768px) {
    [data-template="home-section-1"]:after {
      display: none; } }
  [data-template="home-section-1"] .container {
    padding: 6.375rem 2rem 0 3rem; }
    @media (max-width: 768px) {
      [data-template="home-section-1"] .container {
        padding: 1.375rem 2rem 0 3rem; } }
    @media (max-width: 667px) {
      [data-template="home-section-1"] .container {
        padding: 0 2rem 2rem 2rem; } }
  [data-template="home-section-1"] .row {
    padding: 0 1rem 9.688rem 7rem;
    display: flex; }
    [data-template="home-section-1"] .row > * {
      flex: 1; }
    @supports (display: grid) {
      [data-template="home-section-1"] .row {
        display: grid;
        grid-template-columns: 34% 335px 1fr;
        grid-column-gap: 5.5rem; } }
    @media (max-width: 1024px) {
      [data-template="home-section-1"] .row {
        padding: 2rem;
        grid-template-columns: repeat(2, 1fr);
        text-align: center; }
        [data-template="home-section-1"] .row [data-component="text"] {
          width: 80%;
          margin: 0 auto 5rem; }
          [data-template="home-section-1"] .row [data-component="text"] br {
            display: none; } }
    @media (max-width: 768px) {
      [data-template="home-section-1"] .row {
        padding: 3rem;
        display: block;
        text-align: center; }
        [data-template="home-section-1"] .row [data-component="text"] {
          width: 80%;
          margin: 0 auto 5rem; }
          [data-template="home-section-1"] .row [data-component="text"] br {
            display: none; } }
    @media (max-width: 414px) {
      [data-template="home-section-1"] .row {
        padding: 0; }
        [data-template="home-section-1"] .row [data-component="text"] {
          width: 100%;
          margin: 0 auto 5rem; } }
    [data-template="home-section-1"] .row .item:nth-of-type(3) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end; }
      [data-template="home-section-1"] .row .item:nth-of-type(3) [data-component="image"] {
        max-width: 200px; }
    @media (max-width: 1024px) {
      [data-template="home-section-1"] .row .item:nth-of-type(3) {
        display: none; }
      [data-template="home-section-1"] .row .item [data-component="image"] {
        max-width: 300px; } }
    @media (max-width: 768px) {
      [data-template="home-section-1"] .row .item:nth-of-type(2), [data-template="home-section-1"] .row .item:nth-of-type(3) {
        display: none; } }
  [data-template="home-section-1"] .logoimage-mobile {
    position: relative;
    display: none;
    margin: 0 -3rem 5rem -3rem;
    padding-top: 50px;
    overflow: hidden; }
    [data-template="home-section-1"] .logoimage-mobile:after {
      content: "";
      display: block;
      background-color: #ffc600;
      position: absolute;
      left: -30%;
      top: -50%;
      width: 130%;
      height: 130%;
      transform-origin: center center;
      transform: rotate(-10deg) skewx(10deg);
      z-index: -1; }
    [data-template="home-section-1"] .logoimage-mobile [data-component="image"].product {
      margin: 0 auto;
      width: 100%; }
      @media (max-width: 768px) {
        [data-template="home-section-1"] .logoimage-mobile [data-component="image"].product {
          max-width: 200px; } }
      @media (max-width: 414px) {
        [data-template="home-section-1"] .logoimage-mobile [data-component="image"].product {
          max-width: 110px; } }
    [data-template="home-section-1"] .logoimage-mobile [data-component="image"].year {
      margin: -3rem auto 0;
      max-width: 155px;
      width: 100%;
      position: relative;
      z-index: 9; }
    @media (max-width: 768px) {
      [data-template="home-section-1"] .logoimage-mobile {
        display: block; }
        [data-template="home-section-1"] .logoimage-mobile:after {
          content: "";
          display: block;
          background-color: #ffc600;
          position: absolute;
          left: -20%;
          top: -50%;
          width: 130%;
          height: 130%;
          transform-origin: center center;
          transform: rotate(-5deg) skewx(5deg);
          z-index: -1; } }

[data-template="home-section-2"] {
  padding: 5rem;
  max-width: 1000px;
  margin: 0 auto; }
  @media (max-width: 414px) {
    [data-template="home-section-2"] {
      padding: 3rem 0; } }
  [data-template="home-section-2"] [data-component="text"] {
    text-align: center; }
  [data-template="home-section-2"] [data-component="button"] {
    display: block;
    text-align: center; }

[data-template="home-section-3"] {
  text-align: center;
  background-image: url("../img/home-section-3.png");
  background-size: cover; }
  [data-template="home-section-3"] .container {
    padding: 5.2rem 2rem 4.9rem; }
    @media (max-width: 414px) {
      [data-template="home-section-3"] .container {
        padding: 3rem 1rem; } }
  [data-template="home-section-3"] [data-component="text"] {
    color: #FFFFFF; }

[data-template="home-section-4"] {
  text-align: center;
  background-image: url("../img/home-section-4.png");
  background-size: cover;
  font-size: 16px; }
  [data-template="home-section-4"] .container {
    padding: 15rem 3rem; }
    @media (max-width: 1024px) {
      [data-template="home-section-4"] .container {
        padding: 10rem 3rem; } }
    @media (max-width: 414px) {
      [data-template="home-section-4"] .container {
        padding: 80px 40px; } }
  [data-template="home-section-4"] .list {
    display: flex;
    flex-wrap: wrap;
    margin: 5rem auto -2rem;
    padding-left: 10rem;
    text-align: left;
    overflow-y: auto; }
    @media (max-width: 1440px) {
      [data-template="home-section-4"] .list {
        padding-left: 3rem; } }
    @media (max-width: 1024px) {
      [data-template="home-section-4"] .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 3rem;
        grid-row-gap: 3rem;
        padding: 0;
        max-height: none; } }
    @media (max-width: 700px) {
      [data-template="home-section-4"] .list {
        display: block;
        max-height: 300px; } }
    [data-template="home-section-4"] .list > * {
      width: 33%;
      padding: 0 1rem 2rem 1rem; }
      @media (max-width: 1024px) {
        [data-template="home-section-4"] .list > * {
          width: 100%; } }
      [data-template="home-section-4"] .list > * span {
        font-family: 'Poppins', sans-serif;
        flex-basis: auto;
        display: flex;
        align-items: center;
        font-size: 1.5em;
        color: #312D3B;
        font-weight: 700; }
        [data-template="home-section-4"] .list > * span:before {
          content: "";
          display: block;
          width: 60px;
          height: 60px;
          background-image: url("../img/icon-location.png");
          background-size: cover;
          margin-right: 2.5rem; }
        @media (max-width: 1440px) {
          [data-template="home-section-4"] .list > * span:before {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px; } }
        @media (max-width: 768px) {
          [data-template="home-section-4"] .list > * span {
            font-size: 1.25em; } }
        @media (max-width: 667px) {
          [data-template="home-section-4"] .list > * span {
            font-size: 1.15em; }
            [data-template="home-section-4"] .list > * span:before {
              width: 1.25em;
              height: 1.25em;
              min-width: 1.25em;
              min-height: 1.25em; } }
        @media (max-width: 414px) {
          [data-template="home-section-4"] .list > * span {
            font-size: 1em; }
            [data-template="home-section-4"] .list > * span:before {
              width: 30px;
              height: 30px; } }

[data-template="contactus"] {
  text-align: center;
  background-image: url("../img/home-section-3.png");
  background-size: cover;
  font-size: 16px; }
  [data-template="contactus"] .container {
    padding: 15rem 3rem; }
    @media (max-width: 1024px) {
      [data-template="contactus"] .container {
        padding: 10rem 3rem; } }
    @media (max-width: 1024px) {
      [data-template="contactus"] .container {
        padding: 40px 20px 60px; } }
  [data-template="contactus"] [data-component="text"] {
    color: #FFFFFF; }
  [data-template="contactus"] form {
    max-width: 620px;
    margin: 3rem auto 0; }
    [data-template="contactus"] form .input-area {
      display: flex;
      margin: 0 -1rem; }
      @media (max-width: 414px) {
        [data-template="contactus"] form .input-area {
          display: block;
          margin: 0; } }
    [data-template="contactus"] form .input-text {
      width: 45%;
      margin: 0 1rem;
      flex-basis: auto;
      background-color: #FFFFFF;
      border-radius: 40px;
      overflow: hidden;
      padding: 20px; }
      @media (max-width: 414px) {
        [data-template="contactus"] form .input-text {
          width: 100%;
          margin: 0;
          margin-bottom: 2rem; }
          [data-template="contactus"] form .input-text:last-of-type {
            margin-bottom: 0; } }
      [data-template="contactus"] form .input-text input {
        max-width: 100%;
        width: 100%; }
        @media (max-width: 414px) {
          [data-template="contactus"] form .input-text input {
            width: 100%; } }
    [data-template="contactus"] form textarea {
      padding: 20px;
      margin-top: 2rem;
      margin-bottom: 3.125rem;
      background-color: #FFFFFF;
      border-radius: 10px;
      resize: none;
      max-width: 100%; }
    [data-template="contactus"] form .input-text input, [data-template="contactus"] form textarea {
      font-family: 'Montserrat', sans-serif;
      font-size: 0.875em;
      border: none;
      outline: none; }
      [data-template="contactus"] form .input-text input::-webkit-input-placeholder, [data-template="contactus"] form textarea::-webkit-input-placeholder {
        font-family: 'Montserrat', sans-serif;
        font-size: 1em; }
      [data-template="contactus"] form .input-text input::-moz-placeholder, [data-template="contactus"] form textarea::-moz-placeholder {
        font-family: 'Montserrat', sans-serif;
        font-size: 1em; }
      [data-template="contactus"] form .input-text input:-ms-input-placeholder, [data-template="contactus"] form textarea:-ms-input-placeholder {
        font-family: 'Montserrat', sans-serif;
        font-size: 1em; }
      [data-template="contactus"] form .input-text input:-moz-placeholder, [data-template="contactus"] form textarea:-moz-placeholder {
        font-family: 'Montserrat', sans-serif;
        font-size: 1em; }

[data-template="aboutus-section-1"] {
  position: relative;
  overflow: hidden; }
  [data-template="aboutus-section-1"]:after {
    content: "";
    display: block;
    background-image: url("../img/old_building.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: -8%;
    top: 0;
    width: 60%;
    height: 200%;
    z-index: -1; }
    @media (max-width: 1024px) {
      [data-template="aboutus-section-1"]:after {
        right: -6%; } }
  @media (max-width: 768px) {
    [data-template="aboutus-section-1"]:after {
      display: none; } }
  [data-template="aboutus-section-1"] .container {
    padding: 6.375rem 2rem 0 3rem; }
    @media (max-width: 667px) {
      [data-template="aboutus-section-1"] .container {
        padding: 0 2rem 2rem 2rem; } }
  [data-template="aboutus-section-1"] .row {
    padding: 0 1rem 9.688rem 7rem;
    display: flex; }
    [data-template="aboutus-section-1"] .row > * {
      flex: 1; }
    @supports (display: grid) {
      [data-template="aboutus-section-1"] .row {
        display: grid;
        grid-template-columns: 34% 335px 1fr;
        grid-column-gap: 5.5rem; } }
    @media (max-width: 1024px) {
      [data-template="aboutus-section-1"] .row {
        padding: 2rem;
        grid-template-columns: repeat(2, 1fr);
        text-align: center; }
        [data-template="aboutus-section-1"] .row [data-component="text"] {
          width: 80%;
          margin: 0 auto 5rem; }
          [data-template="aboutus-section-1"] .row [data-component="text"] br {
            display: none; } }
    @media (max-width: 768px) {
      [data-template="aboutus-section-1"] .row {
        padding: 3rem;
        display: block;
        text-align: center; }
        [data-template="aboutus-section-1"] .row [data-component="text"] {
          width: 80%;
          margin: 0 auto 5rem; }
          [data-template="aboutus-section-1"] .row [data-component="text"] br {
            display: none; } }
    @media (max-width: 414px) {
      [data-template="aboutus-section-1"] .row {
        padding: 0; }
        [data-template="aboutus-section-1"] .row [data-component="text"] {
          width: 100%;
          margin: 0 auto 5rem; } }
    [data-template="aboutus-section-1"] .row .item:nth-of-type(3) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end; }
      [data-template="aboutus-section-1"] .row .item:nth-of-type(3) [data-component="image"] {
        max-width: 205px;
        width: 100%; }
    @media (max-width: 1024px) {
      [data-template="aboutus-section-1"] .row .item:nth-of-type(3) {
        display: none; }
      [data-template="aboutus-section-1"] .row .item [data-component="image"] {
        max-width: 300px; } }
    @media (max-width: 768px) {
      [data-template="aboutus-section-1"] .row .item:nth-of-type(2), [data-template="aboutus-section-1"] .row .item:nth-of-type(3) {
        display: none; } }
  [data-template="aboutus-section-1"] .logoimage-mobile {
    position: relative;
    display: none;
    margin: 0 -2rem 5rem -2rem;
    padding-top: 100px;
    overflow: hidden; }
    [data-template="aboutus-section-1"] .logoimage-mobile:after {
      content: "";
      display: block;
      background-color: #ffc600;
      position: absolute;
      left: -30%;
      top: -40%;
      width: 130%;
      height: 130%;
      transform-origin: center center;
      transform: rotate(-7deg) skewx(7deg);
      z-index: -1; }
    [data-template="aboutus-section-1"] .logoimage-mobile [data-component="image"].product {
      margin: 0 auto;
      max-width: 110px;
      width: 100%; }
    [data-template="aboutus-section-1"] .logoimage-mobile [data-component="image"].year {
      margin: 0 auto;
      max-width: 155px;
      width: 100%; }
    @media (max-width: 667px) {
      [data-template="aboutus-section-1"] .logoimage-mobile {
        display: block; } }

[data-template="aboutus-section-2"] {
  padding: 10rem 5rem 5rem;
  margin: 10px 0;
  font-size: 16px;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95) -30%, white 70%), url(../img/aboutus-section-2.png); }
  [data-template="aboutus-section-2"] .inner {
    max-width: 780px;
    margin: 0 auto; }
  [data-template="aboutus-section-2"] [data-component="image"] {
    margin-bottom: 3rem; }
  [data-template="aboutus-section-2"] [data-component="text"] p {
    font-size: 1.125em; }
  [data-template="aboutus-section-2"] [data-component="text"] h4 {
    font-weight: 800; }
  [data-template="aboutus-section-2"] [data-component="text"] [data-component="image"] {
    float: left;
    margin: 0 3rem 3rem 0; }
  [data-template="aboutus-section-2"] .product-show-list {
    display: flex;
    margin: 0 -35px;
    justify-content: center; }
    @media (max-width: 414px) {
      [data-template="aboutus-section-2"] .product-show-list {
        flex-wrap: wrap;
        margin: 0; } }
    [data-template="aboutus-section-2"] .product-show-list > * {
      width: 33%;
      flex-basis: auto;
      margin: 0 35px;
      transition: .3s; }
      @media (max-width: 414px) {
        [data-template="aboutus-section-2"] .product-show-list > * {
          width: 33%;
          margin: 12px 21px; } }
      [data-template="aboutus-section-2"] .product-show-list > *:hover {
        opacity: .75; }
  @media (max-width: 700px) {
    [data-template="aboutus-section-2"] [data-component="text"] [data-component="image"] {
      max-width: 200px; } }
  @media (max-width: 667px) {
    [data-template="aboutus-section-2"] [data-component="text"] [data-component="image"] {
      max-width: none;
      float: none;
      margin: 0 0 3rem 0; } }
  @media (max-width: 414px) {
    [data-template="aboutus-section-2"] {
      padding: 4rem 24px; } }

[data-template="aboutus-section-3"] {
  margin: 10px 0;
  font-size: 16px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(../img/home-section-4.png);
  text-align: center; }
  [data-template="aboutus-section-3"] .container {
    padding: 10rem 5rem 10rem; }
    @media (max-width: 414px) {
      [data-template="aboutus-section-3"] .container {
        padding: 5rem 3rem; } }
  [data-template="aboutus-section-3"] .inner {
    max-width: 350px;
    margin: 0 auto; }
    [data-template="aboutus-section-3"] .inner [data-component="text"] b {
      font-weight: 700; }
    @media (max-width: 414px) {
      [data-template="aboutus-section-3"] .inner [data-component="text"] p {
        font-size: 20px; } }
    @media (max-width: 320px) {
      [data-template="aboutus-section-3"] .inner [data-component="text"] p {
        font-size: 1em; } }
  [data-template="aboutus-section-3"] .title {
    font-weight: 700;
    color: #312D3B;
    font-size: 3em;
    text-transform: uppercase; }
    @media (max-width: 414px) {
      [data-template="aboutus-section-3"] .title {
        font-size: 24px; } }
    [data-template="aboutus-section-3"] .title:after {
      content: "";
      display: block;
      margin: 2.5rem auto;
      height: 8px;
      width: 64px;
      background-color: #FFC600; }

[data-template="brand-section-1"] {
  position: relative;
  overflow: hidden; }
  [data-template="brand-section-1"]:after {
    content: "";
    display: block;
    background-color: #ffc600;
    position: absolute;
    right: -11%;
    top: 0;
    width: 60%;
    height: 200%;
    transform: skewx(-7deg);
    z-index: -1; }
    @media (max-width: 1024px) {
      [data-template="brand-section-1"]:after {
        right: -6%; } }
  [data-template="brand-section-1"] .container {
    padding: 6.375rem 2rem 0 3rem; }
    @media (max-width: 768px) {
      [data-template="brand-section-1"] .container {
        padding: 1.375rem 2rem 0 3rem; } }
  @media (max-width: 768px) {
    [data-template="brand-section-1"] [data-component=button] {
      margin: 2rem 0 3rem; }
    [data-template="brand-section-1"]:after {
      display: none; } }
  @media (max-width: 667px) {
    [data-template="brand-section-1"] {
      padding: 0 2rem 2rem 2rem; } }
  [data-template="brand-section-1"] .row {
    padding: 0 1rem 9.688rem 7rem;
    display: flex; }
    [data-template="brand-section-1"] .row > * {
      flex: 1; }
    @supports (display: grid) {
      [data-template="brand-section-1"] .row {
        display: grid;
        grid-template-columns: 34% 335px 1fr;
        grid-column-gap: 5.5rem; } }
    @media (max-width: 1024px) {
      [data-template="brand-section-1"] .row {
        padding: 2rem;
        grid-template-columns: repeat(2, 1fr);
        text-align: center; }
        [data-template="brand-section-1"] .row [data-component="text"] {
          width: 80%;
          margin: 0 auto 5rem; }
          [data-template="brand-section-1"] .row [data-component="text"] br {
            display: none; } }
    @media (max-width: 768px) {
      [data-template="brand-section-1"] .row {
        padding: 3rem;
        display: block;
        text-align: center; }
        [data-template="brand-section-1"] .row [data-component="text"] {
          width: 80%;
          margin: 0 auto 5rem; }
          [data-template="brand-section-1"] .row [data-component="text"] br {
            display: none; } }
    @media (max-width: 414px) {
      [data-template="brand-section-1"] .row {
        padding: 0; }
        [data-template="brand-section-1"] .row [data-component="text"] {
          width: 100%;
          margin: 0 auto 5rem; } }
    [data-template="brand-section-1"] .row .item:nth-of-type(3) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end; }
      [data-template="brand-section-1"] .row .item:nth-of-type(3) [data-component="image"] {
        max-width: 162px; }
    @media (max-width: 1024px) {
      [data-template="brand-section-1"] .row .item:nth-of-type(3) {
        display: none; }
      [data-template="brand-section-1"] .row .item [data-component="image"] {
        max-width: 300px; } }
    @media (max-width: 768px) {
      [data-template="brand-section-1"] .row .item:nth-of-type(2), [data-template="brand-section-1"] .row .item:nth-of-type(3) {
        display: none; } }
  [data-template="brand-section-1"] .brands {
    display: flex;
    align-items: center;
    margin: 50px -35px; }
    @media (max-width: 1199px) {
      [data-template="brand-section-1"] .brands {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 2rem;
        margin: 3rem 0 10rem; }
        [data-template="brand-section-1"] .brands > * {
          margin: 0; } }
    @media (max-width: 667px) {
      [data-template="brand-section-1"] .brands {
        margin: 3rem 0 5rem; } }
    @media (max-width: 414px) {
      [data-template="brand-section-1"] .brands {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2rem;
        margin: 0; }
        [data-template="brand-section-1"] .brands > * {
          margin: 0; } }
    [data-template="brand-section-1"] .brands > * {
      margin: 0 14.375px;
      width: 100%; }
  [data-template="brand-section-1"] .logoimage-mobile {
    position: relative;
    display: none;
    margin: 0 -5rem 5rem -5rem;
    padding-top: 100px;
    overflow: hidden; }
    [data-template="brand-section-1"] .logoimage-mobile:after {
      content: "";
      display: block;
      background-color: #ffc600;
      position: absolute;
      left: -30%;
      top: -50%;
      width: 130%;
      height: 130%;
      transform-origin: center center;
      transform: rotate(-10deg) skewx(10deg);
      z-index: -1; }
    [data-template="brand-section-1"] .logoimage-mobile [data-component="image"].product {
      margin: 0 auto;
      max-width: 110px;
      width: 100%; }
      @media (max-width: 768px) {
        [data-template="brand-section-1"] .logoimage-mobile [data-component="image"].product {
          max-width: 280px; } }
      @media (max-width: 414px) {
        [data-template="brand-section-1"] .logoimage-mobile [data-component="image"].product {
          max-width: 180px; } }
    [data-template="brand-section-1"] .logoimage-mobile [data-component="image"].year {
      margin: -3rem auto 0;
      max-width: 155px;
      width: 100%; }
    @media (max-width: 768px) {
      [data-template="brand-section-1"] .logoimage-mobile {
        display: block; }
        [data-template="brand-section-1"] .logoimage-mobile:after {
          content: "";
          display: block;
          background-color: #ffc600;
          position: absolute;
          left: -20%;
          top: -50%;
          width: 130%;
          height: 130%;
          transform-origin: center center;
          transform: rotate(-5deg) skewx(5deg);
          z-index: -1; } }

[data-template="brand-section-2"] {
  padding: 7rem 5rem 3rem;
  margin: 10px 0;
  font-size: 16px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(../img/aboutus-section-2.png); }
  @media (max-width: 414px) {
    [data-template="brand-section-2"] {
      padding: 4rem 24px; } }
  [data-template="brand-section-2"] .inner {
    max-width: 780px;
    margin: 0 auto; }
  [data-template="brand-section-2"] [data-component="image"] {
    margin-bottom: 3rem; }
  [data-template="brand-section-2"] [data-component="text"] p {
    font-size: 1.125em; }
  [data-template="brand-section-2"] [data-component="text"] h4 {
    font-weight: 700; }
  [data-template="brand-section-2"] [data-component="text"] [data-component="image"] {
    float: left;
    margin: 0 3rem 3rem 0; }
  [data-template="brand-section-2"] .product-show-list {
    display: flex;
    margin: 0 -35px; }
    [data-template="brand-section-2"] .product-show-list > * {
      width: 33%;
      flex-basis: auto;
      margin: 0 35px;
      transition: .3s; }
      [data-template="brand-section-2"] .product-show-list > *:hover {
        opacity: .75; }

[data-template="brand-section-3"] {
  text-align: center; }
  @media (max-width: 414px) {
    [data-template="brand-section-3"] [data-widget="home-slider"] {
      margin: 5rem 0; } }
  [data-template="brand-section-3"] .container {
    padding: 5rem; }
    @media (max-width: 414px) {
      [data-template="brand-section-3"] .container {
        padding: 5rem 0; } }
  [data-template="brand-section-3"] .inner {
    max-width: 780px;
    margin: 0 auto; }

[data-template="retailers-section-1"] {
  padding: 6.375rem 2rem 3.375rem; }
  [data-template="retailers-section-1"] .inner {
    max-width: 1160px;
    margin: 0 auto; }
    [data-template="retailers-section-1"] .inner [data-component="text"] h2 {
      display: block; }
      [data-template="retailers-section-1"] .inner [data-component="text"] h2:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #F5F5F5;
        margin: 2.5rem 0; }
    [data-template="retailers-section-1"] .inner [data-component="button"] {
      width: 100%;
      text-align: center; }
      @media (max-width: 414px) {
        [data-template="retailers-section-1"] .inner [data-component="button"] {
          margin: 0 0 30px; } }
  [data-template="retailers-section-1"] .retailers-list {
    display: flex;
    margin: 5rem -1rem;
    justify-content: center;
    flex-wrap: wrap; }
    [data-template="retailers-section-1"] .retailers-list .item {
      width: 17%;
      flex-basis: auto;
      margin: 1rem; }
    @media (max-width: 768px) {
      [data-template="retailers-section-1"] .retailers-list .item {
        width: 21%; } }
    @media (max-width: 414px) {
      [data-template="retailers-section-1"] .retailers-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        margin: 0; }
        [data-template="retailers-section-1"] .retailers-list .item {
          width: 100%;
          margin: 0; } }

[data-template="faq-section-1"] .container {
  padding: 6.375rem 2rem 3.375rem; }

[data-template="faq-section-1"] .inner-content,
[data-template="faq-section-1"] .inner {
  max-width: 1160px;
  margin: 0 auto; }

[data-template="marketing-section-1"] {
  padding: 6.375rem 2rem;
  font-size: 16px; }
  [data-template="marketing-section-1"] .inner {
    max-width: 1160px;
    margin: 0 auto; }

[data-template="msds-section-1"] {
  padding: 6.375rem 2rem;
  font-size: 16px; }
  @media (max-width: 800px) {
    [data-template="msds-section-1"] .grid.aside .left {
      padding-top: 3rem; } }
  [data-template="msds-section-1"] .inner {
    max-width: 1160px;
    margin: 0 auto; }
    [data-template="msds-section-1"] .inner [data-component="text"] h2 {
      font-weight: 500; }
    [data-template="msds-section-1"] .inner [data-component="text"] h4 {
      font-weight: 700; }
    [data-template="msds-section-1"] .inner [data-component="text"] b {
      font-weight: 700; }
    [data-template="msds-section-1"] .inner-content {
      margin-bottom: 5rem; }

[data-template="single-section-1"] {
  padding: 6.375rem 2rem;
  font-size: 16px; }
  @media (max-width: 768px) {
    [data-template="single-section-1"] {
      padding: 8.375rem 3rem 0rem; } }
  @media (max-width: 768px) {
    [data-template="single-section-1"] {
      padding: 8.375rem 5rem 0; } }
  @media (max-width: 414px) {
    [data-template="single-section-1"] {
      padding: 5rem 24px 0; } }
  [data-template="single-section-1"] .inner {
    max-width: 1160px;
    margin: 0 auto; }
    [data-template="single-section-1"] .inner .header-mobile {
      display: none; }
      @media (max-width: 768px) {
        [data-template="single-section-1"] .inner .header-mobile {
          display: block; } }
    @media (max-width: 768px) {
      [data-template="single-section-1"] .inner .header-desktop h2 {
        display: none; } }
    [data-template="single-section-1"] .inner .grid.col-2:not(.table) {
      align-items: flex-end; }
      @media (max-width: 768px) {
        [data-template="single-section-1"] .inner .grid.col-2:not(.table) {
          align-items: flex-start; } }
      @media (max-width: 414px) {
        [data-template="single-section-1"] .inner .grid.col-2:not(.table) .mobile-change {
          display: flex;
          flex-direction: column;
          align-items: center; }
          [data-template="single-section-1"] .inner .grid.col-2:not(.table) .mobile-change [data-component="button"] {
            display: inline-flex;
            width: auto;
            order: 1;
            margin-bottom: 3rem; }
            [data-template="single-section-1"] .inner .grid.col-2:not(.table) .mobile-change [data-component="button"] .btn {
              width: auto; }
          [data-template="single-section-1"] .inner .grid.col-2:not(.table) .mobile-change [data-component="text"] {
            order: 2; } }
    [data-template="single-section-1"] .inner .grid.col-2.table {
      margin-top: 10rem;
      align-items: flex-start; }
      @media (max-width: 414px) {
        [data-template="single-section-1"] .inner .grid.col-2.table {
          margin: 3rem 0; } }
      [data-template="single-section-1"] .inner .grid.col-2.table [data-component="text"] p {
        margin-bottom: 1rem; }

[data-template="single-section-2"] {
  padding: 7rem 5rem;
  margin: 10px 0;
  font-size: 16px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(../img/aboutus-section-2.png); }
  @media (max-width: 414px) {
    [data-template="single-section-2"] {
      padding: 5rem 24px; } }
  [data-template="single-section-2"] .inner {
    max-width: 1180px;
    margin: 0 auto; }
  [data-template="single-section-2"] [data-component="material"] {
    padding-left: 150px;
    font-weight: 600;
    margin-top: 5rem; }
    @media (max-width: 1024px) {
      [data-template="single-section-2"] [data-component="material"] {
        padding-left: 75px; } }
    @media (max-width: 768px) {
      [data-template="single-section-2"] [data-component="material"] {
        padding-left: 0;
        margin-top: 24px; } }
  [data-template="single-section-2"] [data-component="text"] {
    text-align: center; }
    [data-template="single-section-2"] [data-component="text"] h2 {
      text-align: center; }

[data-template="single-section-3"] {
  padding: 7rem 5rem 0; }
  @media (max-width: 414px) {
    [data-template="single-section-3"] {
      padding: 5rem 0; } }
  [data-template="single-section-3"] .inner {
    max-width: 1160px;
    margin: 0 auto; }
  [data-template="single-section-3"] [data-component="text"] h2 {
    text-align: center; }
  [data-template="single-section-3"] [data-component="products-list"] .figure,
  [data-template="single-section-3"] [data-component="products-list"] figure {
    width: 22%;
    margin: 0 1rem 0.875rem; }
    @media (max-width: 1024px) {
      [data-template="single-section-3"] [data-component="products-list"] .figure .description,
      [data-template="single-section-3"] [data-component="products-list"] figure .description {
        transform: translatey(160px); } }
    @media (max-width: 768px) {
      [data-template="single-section-3"] [data-component="products-list"] .figure,
      [data-template="single-section-3"] [data-component="products-list"] figure {
        width: 100%;
        margin: auto; }
        [data-template="single-section-3"] [data-component="products-list"] .figure .description,
        [data-template="single-section-3"] [data-component="products-list"] figure .description {
          transform: translatey(140px); } }
    @media (max-width: 414px) {
      [data-template="single-section-3"] [data-component="products-list"] .figure,
      [data-template="single-section-3"] [data-component="products-list"] figure {
        width: 250px;
        margin: 0 auto; }
        [data-template="single-section-3"] [data-component="products-list"] .figure .description,
        [data-template="single-section-3"] [data-component="products-list"] figure .description {
          transform: translatey(120px); } }

[data-template="single-section-4"] {
  padding: 5rem; }
  @media (max-width: 414px) {
    [data-template="single-section-4"] {
      padding: 0 24px 3rem; } }
  [data-template="single-section-4"] .inner {
    max-width: 1160px;
    margin: 0 auto; }
    [data-template="single-section-4"] .inner [data-component="text"] h2 {
      display: block;
      text-align: center; }
      [data-template="single-section-4"] .inner [data-component="text"] h2:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #F5F5F5;
        margin: 2.5rem 0; }

[data-template="products-section-1"] {
  padding: 7rem 5rem 0; }
  @media (max-width: 1024px) {
    [data-template="products-section-1"] {
      padding: 7rem 2rem 0; }
      [data-template="products-section-1"] .grid.lefty [data-component=products-list] {
        margin: 0; }
        [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure,
        [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure {
          flex: 1 33%;
          margin: 5px; }
          [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure .description,
          [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure .description {
            transform: translatey(130px); }
          [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure:hover .description,
          [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure:hover .description {
            transform: translatey(0); }
      [data-template="products-section-1"] .grid.lefty .left {
        margin-right: 35px;
        min-width: 240px;
        padding-right: 15px;
        padding-left: 15px; }
      [data-template="products-section-1"] .grid.lefty .right {
        padding-left: 0; }
      [data-template="products-section-1"] .grid.lefty [data-component="category-list"] .list {
        font-size: 16px; }
        [data-template="products-section-1"] .grid.lefty [data-component="category-list"] .list > * > * {
          line-height: 2.5; } }
  @media (max-width: 800px) {
    [data-template="products-section-1"] .grid.lefty .left {
      min-width: 280px;
      margin-right: 15px; }
    [data-template="products-section-1"] .grid.lefty [data-component=products-list] {
      margin: 0; }
      [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure,
      [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure {
        width: auto;
        flex: 1 45%; } }
  @media (max-width: 768px) {
    [data-template="products-section-1"] .grid.lefty .left {
      margin-right: 10px; }
    [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure .description,
    [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure .description {
      transform: translatey(140px); }
    [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure:hover .description,
    [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure:hover .description {
      transform: translatey(0); }
    [data-template="products-section-1"] .grid.lefty .right {
      padding-left: 0; } }
  @media (max-width: 700px) {
    [data-template="products-section-1"] .grid.lefty [data-component=products-list] {
      display: block; }
      [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure .description,
      [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure .description {
        transform: translatey(125px); }
        [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure .description .title .name,
        [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure .description .title .name {
          width: 75%;
          padding-right: 50px; }
        [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure .description .content,
        [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure .description .content {
          display: block; }
      [data-template="products-section-1"] .grid.lefty [data-component=products-list] .figure:hover .description,
      [data-template="products-section-1"] .grid.lefty [data-component=products-list] figure:hover .description {
        background-color: #FFC600;
        transform: translatey(0); }
      [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten {
        display: block; }
        [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten .figure,
        [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten figure {
          max-width: unset;
          width: auto;
          height: auto;
          padding: 0 16px;
          margin-bottom: 10px; }
          [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten .figure [data-component="image"],
          [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten figure [data-component="image"] {
            width: 38px;
            min-width: 38px; }
          [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten .figure .description,
          [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten figure .description {
            position: static;
            transform: none; }
            [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten .figure .description .title,
            [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten figure .description .title {
              align-items: flex-start; }
              [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten .figure .description .title .name,
              [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten figure .description .title .name {
                padding-right: 10px;
                font-size: 18px; }
            [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten .figure .description .content,
            [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten figure .description .content {
              display: none; }
          [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten .figure:hover .description,
          [data-template="products-section-1"] .grid.lefty [data-component=products-list].shorten figure:hover .description {
            background-color: unset;
            transform: translatey(0); } }
  [data-template="products-section-1"] .inner {
    max-width: 1160px;
    margin: 0 auto; }
    [data-template="products-section-1"] .inner [data-component=products-list] {
      margin-top: 0;
      margin-bottom: 0; }
  [data-template="products-section-1"] .grid-navigation {
    margin-bottom: 2rem;
    display: none; }
    @media (max-width: 700px) {
      [data-template="products-section-1"] .grid-navigation {
        display: block; } }
    [data-template="products-section-1"] .grid-navigation > * {
      font-size: 18px;
      font-weight: 600;
      display: inline-block;
      background-color: #ADADAD;
      color: #FFFFFF;
      text-transform: uppercase;
      padding: 10px;
      text-align: center; }
      [data-template="products-section-1"] .grid-navigation > *.active {
        background-color: #312D3B; }
      [data-template="products-section-1"] .grid-navigation > *:first-of-type {
        margin-right: 8px; }
  @media (max-width: 700px) {
    [data-template="products-section-1"] {
      padding: 5rem 24px; }
      [data-template="products-section-1"] .grid.lefty {
        flex-direction: column;
        padding-top: 2rem; }
        [data-template="products-section-1"] .grid.lefty .left, [data-template="products-section-1"] .grid.lefty .right {
          width: 100%;
          margin: 0; }
        [data-template="products-section-1"] .grid.lefty .left {
          order: 2;
          padding-left: 0;
          padding-right: 0;
          border-right: none; }
        [data-template="products-section-1"] .grid.lefty .right {
          order: 1; }
        [data-template="products-section-1"] .grid.lefty .category-block {
          position: absolute;
          top: 85px;
          left: 0;
          background-color: #fff;
          height: 0;
          width: 100%;
          z-index: 10;
          overflow: hidden; }
          [data-template="products-section-1"] .grid.lefty .category-block [data-component="category-list"] {
            margin-bottom: 30px;
            padding: 0 15px; }
        [data-template="products-section-1"] .grid.lefty.product-menu_active .product-menu {
          position: relative; }
          [data-template="products-section-1"] .grid.lefty.product-menu_active .product-menu span {
            z-index: 2;
            position: relative; }
          [data-template="products-section-1"] .grid.lefty.product-menu_active .product-menu:before {
            content: "";
            position: absolute;
            left: -24px;
            top: 0;
            width: 200vw;
            height: 100%;
            background-color: #312d3b;
            z-index: 1; }
          [data-template="products-section-1"] .grid.lefty.product-menu_active .product-menu:after {
            content: "";
            position: absolute;
            left: -24px;
            top: 0;
            width: 200%;
            height: 100%;
            background-color: #312d3b;
            z-index: 1; }
        [data-template="products-section-1"] .grid.lefty.product-menu_active .category-block {
          height: auto; }
        [data-template="products-section-1"] .grid.lefty [data-component=advert-area] {
          border-top: 2px solid #f5f5f5;
          border-bottom: none;
          padding: 2rem 24px 2rem; }
          [data-template="products-section-1"] .grid.lefty [data-component=advert-area]:first-child {
            margin-top: 2rem; }
        [data-template="products-section-1"] .grid.lefty [data-component=products-list] {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 20px; } }
  @media (max-width: 414px) {
    [data-template="products-section-1"] .grid.lefty [data-component=products-list] {
      display: block; } }

@media (min-width: 701px) {
  [data-template="products-section-1"] .grid.lefty .left {
    max-width: 280px; } }

footer {
  background-color: #2D2D2D;
  font-size: 16px; }
  @media (max-width: 414px) {
    footer {
      display: block;
      text-align: center; } }
  footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 100px; }
    @media (max-width: 768px) {
      footer .container {
        padding: 30px 50px; } }
    @media (max-width: 414px) {
      footer .container {
        display: block; } }
  footer .item {
    display: inline-flex;
    align-items: center; }
    @media (max-width: 768px) {
      footer .item {
        display: block; } }
    footer .item [data-component="text"] {
      color: #FFFFFF; }
      footer .item [data-component="text"] p {
        font-size: 1em;
        margin-bottom: 0; }
  footer .footer-logo {
    display: flex; }
    footer .footer-logo img {
      max-width: 117px; }
    @media (max-width: 768px) {
      footer .footer-logo {
        margin-bottom: 2rem; } }
    @media (max-width: 414px) {
      footer .footer-logo {
        width: 125px;
        margin: 0 auto 1.5rem;
        display: block;
        text-align: center; } }
    footer .footer-logo:after {
      content: "";
      display: block;
      width: 2px;
      background-color: #FFFFFF;
      margin: 0 2.5rem; }
      @media (max-width: 768px) {
        footer .footer-logo:after {
          display: none; } }
  footer .footer-social {
    display: flex;
    align-items: center;
    margin: 0 -1rem; }
    footer .footer-social > * {
      margin: 0 1rem;
      transition: .3s; }
      footer .footer-social > *:hover {
        opacity: .75; }
    @media (max-width: 414px) {
      footer .footer-social {
        margin: 2rem 0;
        justify-content: center; } }

[data-widget="home-slider"] {
  position: relative;
  max-width: 780px;
  width: 100%;
  margin: 5rem auto; }
  [data-widget="home-slider"]:after {
    content: "";
    width: 85%;
    height: 50%;
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 30%;
    box-shadow: 0 30px 60px -5px rgba(0, 0, 0, 0.25);
    z-index: -1; }
    @media (max-width: 414px) {
      [data-widget="home-slider"]:after {
        box-shadow: none; } }
  [data-widget="home-slider"][data-component="products-list"] {
    display: none; }
    [data-widget="home-slider"][data-component="products-list"] .swiper-wrapper {
      margin: 0 auto; }
    @media (max-width: 768px) {
      [data-widget="home-slider"][data-component="products-list"] {
        display: flex; } }
    [data-widget="home-slider"][data-component="products-list"]:after {
      display: none; }
  @media (max-width: 414px) {
    [data-widget="home-slider"] {
      margin: 5rem 0 3rem; } }
  [data-widget="home-slider"].popular-products .swiper-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: -13%;
    width: 126%;
    height: 0; }
    @media (max-width: 1000px) {
      [data-widget="home-slider"].popular-products .swiper-navigation {
        left: -25px;
        width: 107%; } }
    @media (max-width: 414px) {
      [data-widget="home-slider"].popular-products .swiper-navigation {
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto; } }
  [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(even) .products-container > *:nth-of-type(1) {
    background-color: #FFC600; }
    [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(even) .products-container > *:nth-of-type(1) [data-component="text"] {
      color: #312D3B; }
  [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(even) .products-container > *:nth-of-type(2) {
    background-color: #312D3B; }
    [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(even) .products-container > *:nth-of-type(2) [data-component="text"] {
      color: #FFFFFF; }
  [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(odd) .products-container > *:nth-of-type(1) {
    background-color: #312D3B; }
    [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(odd) .products-container > *:nth-of-type(1) [data-component="text"] {
      color: #FFFFFF; }
  [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(odd) .products-container > *:nth-of-type(2) {
    background-color: #FFC600; }
    [data-widget="home-slider"].popular-products .swiper-slide:nth-of-type(odd) .products-container > *:nth-of-type(2) [data-component="text"] {
      color: #312D3B; }
  [data-widget="home-slider"].popular-products .swiper-button-prev {
    background-image: url("../img/svg/circleblack.svg");
    top: 50%;
    left: -100px; }
  [data-widget="home-slider"].popular-products .swiper-button-next {
    background-image: url("../img/svg/circleblack.svg");
    top: 50%;
    right: -50px;
    transform: rotate(180deg); }
  [data-widget="home-slider"].popular-products .swiper-button-prev, [data-widget="home-slider"].popular-products .swiper-button-next {
    border: 2px solid #312D3B; }
    @media (max-width: 1000px) {
      [data-widget="home-slider"].popular-products .swiper-button-prev, [data-widget="home-slider"].popular-products .swiper-button-next {
        border: none;
        left: 0;
        right: 0; } }
  [data-widget="home-slider"].product-categories .swiper-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem; }
  [data-widget="home-slider"].product-categories .swiper-button-prev, [data-widget="home-slider"].product-categories .swiper-button-next {
    display: block;
    border: 2px solid #FFFFFF; }
  [data-widget="home-slider"].product-categories .swiper-button-prev {
    background-image: url("../img/svg/circlewhite.svg");
    margin-right: 3rem; }
  [data-widget="home-slider"].product-categories .swiper-button-next {
    background-image: url("../img/svg/circlewhite.svg");
    transform: rotate(180deg); }
  [data-widget="home-slider"] .swiper-container {
    overflow: hidden;
    position: relative; }
  [data-widget="home-slider"] .swiper-wrapper {
    display: flex; }
  [data-widget="home-slider"] .swiper-slide {
    flex-shrink: 0;
    position: relative; }
  [data-widget="home-slider"] [class*="swiper-button-"] {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-size: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    outline: none;
    transition: .3s; }
    [data-widget="home-slider"] [class*="swiper-button-"]:hover:not(.swiper-button-disabled) {
      opacity: .65; }
      @media (max-width: 1024px) {
        [data-widget="home-slider"] [class*="swiper-button-"]:hover:not(.swiper-button-disabled) {
          opacity: 1; } }
  [data-widget="home-slider"] .swiper-button-disabled {
    opacity: .2; }
  [data-widget="home-slider"] .products-container {
    text-align: center;
    font-size: 16px; }
    [data-widget="home-slider"] .products-container [data-component="text"] {
      font-family: "Montserrat", sans-serif;
      font-size: 1.5em;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 1.1; }
      @media (max-width: 414px) {
        [data-widget="home-slider"] .products-container [data-component="text"] {
          font-size: 1em; } }
    [data-widget="home-slider"] .products-container [data-component="image"] {
      margin-bottom: 2rem; }
      [data-widget="home-slider"] .products-container [data-component="image"] img {
        width: auto;
        height: auto;
        max-height: 160px;
        max-width: 160px; }
        @media (max-width: 414px) {
          [data-widget="home-slider"] .products-container [data-component="image"] img {
            max-width: 100%; } }
    [data-widget="home-slider"] .products-container > * {
      padding: 40px;
      height: 320px; }

[data-widget="product-categories"] {
  font-size: 16px;
  margin: 8.25rem 0 4.4rem; }
  @media (max-width: 414px) {
    [data-widget="product-categories"] {
      margin: 5rem 0 3rem; } }
  [data-widget="product-categories"] .product-categories_navigation {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 85%;
    margin: 0 auto; }
    @media (max-width: 1024px) {
      [data-widget="product-categories"] .product-categories_navigation {
        width: 100%; } }
    @media (max-width: 414px) {
      [data-widget="product-categories"] .product-categories_navigation {
        width: 100%; } }
    [data-widget="product-categories"] .product-categories_navigation .item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      height: 30px;
      font-weight: 600;
      letter-spacing: 3px;
      padding: 1rem 2rem;
      text-transform: uppercase;
      font-size: .875em;
      margin-bottom: 1.7rem;
      cursor: pointer;
      transition: .3s; }
      @media (max-width: 414px) {
        [data-widget="product-categories"] .product-categories_navigation .item {
          margin-bottom: 1rem; } }
      [data-widget="product-categories"] .product-categories_navigation .item:hover:not(.active) {
        color: #FFC600; }
      [data-widget="product-categories"] .product-categories_navigation .item.active {
        border-radius: 40px;
        color: #312D3B;
        background-color: #FFC600;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5); }
    [data-widget="product-categories"] .product-categories_navigation .brake {
      width: 100%; }

.product-categories__item {
  width: 190px;
  margin: 0 22px 44px; }
  .product-categories__items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 4.3rem; }

.product-categories__wrap {
  max-width: 1100px;
  margin: 0 auto; }

[data-widget="accordion"] {
  font-size: 16px;
  box-shadow: 6px 1px 13px 0 rgba(0, 0, 0, 0.09);
  margin: 2.5rem 0;
  cursor: pointer;
  transition: .3s; }
  [data-widget="accordion"]:first-of-type {
    margin-top: 0; }
  [data-widget="accordion"]:hover {
    opacity: .75; }
  [data-widget="accordion"].accordion-active:hover {
    opacity: 1; }
  [data-widget="accordion"] .title {
    font-size: 24px;
    color: #4E4E4E;
    font-weight: 800;
    border-left: 8px solid #FFC600;
    padding: 25px 60px 25px 25px;
    position: relative; }
    [data-widget="accordion"] .title:before {
      content: "";
      position: absolute;
      right: 25px;
      width: 25px;
      height: 25px;
      background-image: url("../img/svg/shape.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: .3s; }
    @media (max-width: 414px) {
      [data-widget="accordion"] .title {
        font-size: 18px; } }
  [data-widget="accordion"] .content {
    display: none;
    border-left: 8px solid #F5F5F5;
    margin-top: -25px; }
    @media (max-width: 414px) {
      [data-widget="accordion"] .content {
        border: none; } }
    [data-widget="accordion"] .content-inner {
      padding: 0 25px 25px; }
      @media (max-width: 414px) {
        [data-widget="accordion"] .content-inner {
          padding-left: 0;
          margin-top: 3rem; } }
      [data-widget="accordion"] .content-inner:before {
        content: "";
        display: block;
        width: 100%;
        padding: 1px;
        margin-bottom: 25px;
        background-color: #F5F5F5;
        margin-top: 25px; }
  [data-widget="accordion"] [data-component="text"] h5 {
    font-weight: 800; }
  [data-widget="accordion"] [data-component="text"] p {
    font-size: 1.125em; }
    @media (max-width: 414px) {
      [data-widget="accordion"] [data-component="text"] p {
        line-height: 1.35; } }
  @media (max-width: 414px) {
    [data-widget="accordion"].accordion-active {
      box-shadow: none; } }
  [data-widget="accordion"].accordion-active .title:before {
    transform: rotate(180deg); }
  [data-widget="accordion"].accordion-active .content {
    display: block; }

[data-widget="accordion-products"] {
  display: flex;
  margin-bottom: 40px; }
  @media (max-width: 700px) {
    [data-widget="accordion-products"] {
      display: block; } }
  [data-widget="accordion-products"] .accordion {
    position: relative;
    width: 92%;
    height: 4rem;
    margin-right: 3rem; }
    @media (max-width: 1024px) {
      [data-widget="accordion-products"] .accordion {
        height: 5rem; } }
    @media (max-width: 800px) {
      [data-widget="accordion-products"] .accordion {
        height: 5rem;
        margin-bottom: 3rem; } }
    [data-widget="accordion-products"] .accordion .container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid #312D3B;
      background-color: #FFFFFF;
      padding: 23px 40px 20px;
      border-radius: 40px;
      height: 100%;
      overflow: hidden;
      user-select: none;
      cursor: pointer; }
      [data-widget="accordion-products"] .accordion .container > * {
        display: block;
        font-size: 1.125em;
        margin-bottom: 2rem;
        font-weight: 600;
        pointer-events: none;
        order: 2;
        z-index: 3;
        transition: .3s;
        cursor: pointer; }
        @media (max-width: 414px) {
          [data-widget="accordion-products"] .accordion .container > * {
            font-size: 1em;
            line-height: 1.25; } }
        [data-widget="accordion-products"] .accordion .container > *:hover:not(.active) {
          opacity: .5; }
        [data-widget="accordion-products"] .accordion .container > *.active {
          order: 1;
          border-bottom: 2px solid #F5F5F5;
          padding-bottom: 2rem; }
    @media (max-width: 414px) {
      [data-widget="accordion-products"] .accordion {
        height: 60px;
        margin-right: 0;
        margin-bottom: 2rem;
        width: 100%; }
        [data-widget="accordion-products"] .accordion .container {
          padding: 10px 50px 10px 20px; } }
    [data-widget="accordion-products"] .accordion.accordion-active {
      z-index: 1; }
      [data-widget="accordion-products"] .accordion.accordion-active:after {
        z-index: 3; }
      [data-widget="accordion-products"] .accordion.accordion-active .container {
        height: auto;
        z-index: 2;
        cursor: auto; }
        [data-widget="accordion-products"] .accordion.accordion-active .container > * {
          pointer-events: auto; }
    [data-widget="accordion-products"] .accordion:after {
      content: "";
      position: absolute;
      top: 27px;
      right: 40px;
      width: 14px;
      height: 14px;
      background-image: url("../img/svg/rectangle.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: .3s;
      z-index: 1; }
      @media (max-width: 414px) {
        [data-widget="accordion-products"] .accordion:after {
          right: 20px;
          top: 24px; } }
  [data-widget="accordion-products"] .submit {
    width: 25%; }
    [data-widget="accordion-products"] .submit [data-component="button"] {
      display: block; }
      [data-widget="accordion-products"] .submit [data-component="button"] .btn {
        display: block; }
    @media (max-width: 414px) {
      [data-widget="accordion-products"] .submit {
        width: auto; }
        [data-widget="accordion-products"] .submit [data-component="button"] {
          width: 160px; } }

[data-widget="image-zoom"] .zoom-navigation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem; }
  [data-widget="image-zoom"] .zoom-navigation .plus, [data-widget="image-zoom"] .zoom-navigation .minus {
    transition: .3s;
    cursor: pointer; }
    [data-widget="image-zoom"] .zoom-navigation .plus:hover, [data-widget="image-zoom"] .zoom-navigation .minus:hover {
      opacity: .75; }
  [data-widget="image-zoom"] .zoom-navigation .plus {
    margin-right: 8px; }

[data-widget="image-zoom"] .zoom-in {
  transform: scale(1.35); }

[data-widget="image-zoom"] .zoom-image {
  max-width: 230px;
  margin: 0 auto;
  text-align: center; }
  [data-widget="image-zoom"] .zoom-image_disabled img {
    width: 100%; }
  @media (max-width: 768px) {
    [data-widget="image-zoom"] .zoom-image_disabled .zoom-in {
      transform: scale(1); } }
  [data-widget="image-zoom"] .zoom-image img {
    transition: .5s;
    transform-origin: center center; }

[data-component="weblogo"] {
  font-size: 22px;
  display: flex;
  align-items: center;
  margin-bottom: -2.25rem;
  margin-right: 5.438rem; }
  [data-component="weblogo"] a img {
    max-width: 100%;
    width: 100%;
    height: 100%; }
  @media (max-width: 1299px) {
    [data-component="weblogo"] {
      width: 130px;
      margin: -1rem 0 0 0; } }

[data-component="webnavigation"] {
  display: flex;
  align-items: center;
  margin-left: auto; }
  @media (max-width: 1299px) {
    [data-component="webnavigation"] {
      display: block;
      border-top: 2px solid #f2f2f2;
      padding-top: 2rem;
      margin: 2rem 0 4rem; }
      [data-component="webnavigation"] > * {
        display: block;
        margin-bottom: 2rem; } }
  [data-component="webnavigation"] a {
    font-size: 0.875em;
    text-decoration: none;
    color: #3B3B3B;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 2rem;
    transition: .3s; }
    [data-component="webnavigation"] a:last-of-type {
      margin-right: 4rem; }
    [data-component="webnavigation"] a:hover {
      opacity: .5; }
    [data-component="webnavigation"] a.active {
      pointer-events: none;
      font-size: .875em;
      font-weight: 800; }

[data-component="websearch"] {
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  padding: .75rem 1rem;
  max-width: 230px;
  width: 100%; }
  @media (max-width: 1299px) {
    [data-component="websearch"] {
      max-width: 400px; } }
  @media (max-width: 414px) {
    [data-component="websearch"] {
      max-width: 100%; } }
  [data-component="websearch"]:before {
    content: "";
    min-width: 30px;
    min-height: 30px;
    background-image: url("../img/svg/search.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1rem; }
  [data-component="websearch"] input {
    border: none;
    outline: none;
    background: none;
    font-family: 'Montserrat', sans-serif;
    font-size: .875em; }
    [data-component="websearch"] input::-webkit-input-placeholder {
      font-family: 'Montserrat', sans-serif;
      font-size: 1em; }
    [data-component="websearch"] input::-moz-placeholder {
      font-family: 'Montserrat', sans-serif;
      font-size: 1em; }
    [data-component="websearch"] input:-ms-input-placeholder {
      font-family: 'Montserrat', sans-serif;
      font-size: 1em; }
    [data-component="websearch"] input:-moz-placeholder {
      font-family: 'Montserrat', sans-serif;
      font-size: 1em; }

[data-component="button"] {
  display: inline-block;
  font-size: 16px; }
  [data-component="button"] .btn {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #312D3B;
    font-size: .875em;
    font-weight: 700;
    letter-spacing: 3px;
    background-color: #FFC600;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    border-radius: 40px;
    padding: 1.7em 2.5em;
    outline: none;
    border: none;
    cursor: pointer;
    transition: .3s;
    -webkit-appearance: none;
    text-align: center;
    justify-content: center;
    user-select: none; }
    [data-component="button"] .btn:hover {
      background-color: #eab706; }

[data-component="text"] {
  font-size: 16px;
  color: #312D3B; }
  [data-component="text"] h1, [data-component="text"] h2 {
    font-weight: 800; }
  [data-component="text"] h2 {
    text-transform: uppercase;
    font-size: 3em;
    margin-bottom: 1.688rem;
    line-height: 1.1; }
    @media (max-width: 768px) {
      [data-component="text"] h2 {
        font-size: 2em; } }
    @media (max-width: 414px) {
      [data-component="text"] h2 {
        font-size: 1.5em; } }
  [data-component="text"] h4 {
    text-transform: uppercase;
    font-size: 1.5em;
    margin-bottom: 1.688rem;
    line-height: 1.1; }
  [data-component="text"] h5 {
    font-weight: 600;
    font-size: 1.125em;
    margin-bottom: 1em;
    line-height: 1.35; }
  [data-component="text"] p {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 2.75rem; }
    @media (max-width: 768px) {
      [data-component="text"] p {
        font-size: 1.5em;
        line-height: 1.75; } }
    @media (max-width: 414px) {
      [data-component="text"] p {
        font-size: 1em;
        line-height: 2; } }
  [data-component="text"] .featured-title {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    text-transform: capitalize;
    color: #312D3B;
    font-weight: 700;
    text-align: center; }
    [data-component="text"] .featured-title span {
      margin: 0 1.25rem; }
    @media (max-width: 414px) {
      [data-component="text"] .featured-title {
        display: block;
        margin-top: 55px;
        margin-bottom: 40px; }
        [data-component="text"] .featured-title:after {
          display: none; } }
    [data-component="text"] .featured-title:before, [data-component="text"] .featured-title:after {
      content: "";
      height: 1px;
      background-color: #312D3B;
      flex: 1; }
      @media (max-width: 414px) {
        [data-component="text"] .featured-title:before, [data-component="text"] .featured-title:after {
          display: none; } }
  [data-component="text"] b {
    font-weight: 600; }
  [data-component="text"] a {
    color: inherit;
    text-decoration: none;
    font-weight: 600; }

[data-component="image"] .swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }
  [data-component="image"] .swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity; }
    [data-component="image"] .swiper-container-fade .swiper-slide .swiper-slide {
      pointer-events: none; }
    [data-component="image"] .swiper-container-fade .swiper-slide-active {
      pointer-events: auto; }
      [data-component="image"] .swiper-container-fade .swiper-slide-active .swiper-slide-active {
        pointer-events: auto; }
  [data-component="image"] .swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000; }

[data-component="image"] .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

[data-component="image"] .swiper-pagination-lock {
  display: none; }

[data-component="image"] .swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

[data-component="image"] .swiper-button-lock {
  display: none; }

[data-component="image"] .swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform; }

[data-component="image"].desktop {
  max-width: 200px; }
  @media (max-width: 768px) {
    [data-component="image"].desktop {
      display: none; } }

[data-component="image"][data-align="center"] {
  text-align: center;
  float: none !important; }
  [data-component="image"][data-align="center"] img {
    width: auto; }

[data-component="image"] img {
  max-width: 100%;
  width: 100%;
  vertical-align: middle; }

[data-component="social"] {
  display: flex;
  justify-content: center;
  margin: 0 -10px; }
  [data-component="social"] > * {
    width: 35px;
    margin: 0 10px;
    flex-basis: auto;
    transition: .3s; }
    [data-component="social"] > *:hover {
      opacity: .75; }

[data-component="products-list"] {
  margin: 8rem -2.5rem 4rem;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap; }
  [data-component="products-list"] [data-component=image] img {
    max-height: 150px;
    max-width: 100%;
    width: auto; }
  @media (max-width: 768px) {
    [data-component="products-list"] {
      margin: 3rem 0; } }
  @media (max-width: 414px) {
    [data-component="products-list"].products-page .figure,
    [data-component="products-list"].products-page figure {
      margin-bottom: 24px; } }
  [data-component="products-list"] .figure,
  [data-component="products-list"] figure {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4.25rem 3.75rem 7.375rem;
    background: #F5F5F5;
    height: 396px;
    transition: .3s;
    width: calc( 100% / 3 - 2rem);
    margin: 0 1rem 0.875rem;
    overflow: hidden; }
    @media (max-width: 768px) {
      [data-component="products-list"] .figure [data-component="image"],
      [data-component="products-list"] figure [data-component="image"] {
        max-width: 120px; } }
    @media (max-width: 768px) {
      [data-component="products-list"] .figure,
      [data-component="products-list"] figure {
        width: 250px;
        margin: auto; } }
    [data-component="products-list"] .figure [data-component="image"],
    [data-component="products-list"] figure [data-component="image"] {
      transition: .3s;
      transform-origin: top center;
      max-width: 150px; }
    [data-component="products-list"] .figure .description,
    [data-component="products-list"] figure .description {
      text-align: left;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 22px 16px 21px;
      transform: translatey(127px);
      transition: transform .4s, background-color .2s; }
      [data-component="products-list"] .figure .description .title,
      [data-component="products-list"] figure .description .title {
        display: flex;
        align-items: center; }
        [data-component="products-list"] .figure .description .title .name,
        [data-component="products-list"] figure .description .title .name {
          font-size: 1.125em;
          color: #312D3B;
          font-weight: 700;
          line-height: 1.25;
          width: 70%; }
        [data-component="products-list"] .figure .description .title .isavailable,
        [data-component="products-list"] figure .description .title .isavailable {
          text-transform: uppercase;
          text-align: center;
          padding: 0 3px;
          transition: .3s; }
          [data-component="products-list"] .figure .description .title .isavailable .top,
          [data-component="products-list"] figure .description .title .isavailable .top {
            font-size: 10px;
            margin-bottom: 5px; }
          [data-component="products-list"] .figure .description .title .isavailable .bottom,
          [data-component="products-list"] figure .description .title .isavailable .bottom {
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: #FFC600; }
      [data-component="products-list"] .figure .description .content,
      [data-component="products-list"] figure .description .content {
        border-top: 1px solid rgba(0, 0, 0, 0.23);
        margin-top: 21px;
        padding-top: 15px; }
        [data-component="products-list"] .figure .description .content .link,
        [data-component="products-list"] figure .description .content .link {
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 600; }
        [data-component="products-list"] .figure .description .content [data-component="text"] p,
        [data-component="products-list"] figure .description .content [data-component="text"] p {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 0; }
    [data-component="products-list"] .figure:hover [data-component="image"],
    [data-component="products-list"] figure:hover [data-component="image"] {
      transform: translatey(-20%) scale(0.65); }
    [data-component="products-list"] .figure:hover .description,
    [data-component="products-list"] figure:hover .description {
      transform: translatey(0);
      background-color: #FFC600; }
      [data-component="products-list"] .figure:hover .description .title .isavailable .bottom,
      [data-component="products-list"] figure:hover .description .title .isavailable .bottom {
        color: #FFFFFF; }
  @media (max-width: 700px) {
    [data-component="products-list"].shorten {
      display: block; } }
  @media (max-width: 414px) {
    [data-component="products-list"].shorten .figure,
    [data-component="products-list"].shorten figure {
      max-width: 300px;
      width: auto;
      height: auto;
      padding: 0 16px;
      margin-bottom: 10px; }
      [data-component="products-list"].shorten .figure:hover [data-component="image"],
      [data-component="products-list"].shorten figure:hover [data-component="image"] {
        transform: none; }
      [data-component="products-list"].shorten .figure:hover .description,
      [data-component="products-list"].shorten figure:hover .description {
        transform: none;
        background-color: unset; }
        [data-component="products-list"].shorten .figure:hover .description .title .name,
        [data-component="products-list"].shorten figure:hover .description .title .name {
          width: 90%; }
        [data-component="products-list"].shorten .figure:hover .description .title .isavailable .bottom,
        [data-component="products-list"].shorten figure:hover .description .title .isavailable .bottom {
          color: #FFC600; }
      [data-component="products-list"].shorten .figure [data-component="image"],
      [data-component="products-list"].shorten figure [data-component="image"] {
        width: 38px;
        min-width: 38px; }
      [data-component="products-list"].shorten .figure .description,
      [data-component="products-list"].shorten figure .description {
        position: static;
        transform: none; }
        [data-component="products-list"].shorten .figure .description .title,
        [data-component="products-list"].shorten figure .description .title {
          align-items: flex-start; }
          [data-component="products-list"].shorten .figure .description .title .name,
          [data-component="products-list"].shorten figure .description .title .name {
            font-size: 18px; }
        [data-component="products-list"].shorten .figure .description .content,
        [data-component="products-list"].shorten figure .description .content {
          display: none; } }

@media (max-width: 768px) {
  [data-component="banners"] {
    display: flex;
    margin: 6rem -10px 3rem; }
    [data-component="banners"] > * {
      margin: 0 10px; } }

@media (max-width: 667px) {
  [data-component="banners"] {
    display: block;
    margin: 5rem 0 0 0; }
    [data-component="banners"] > * {
      margin: 10px 0; } }

[data-component="banners"] .figure,
[data-component="banners"] figure {
  display: block;
  margin-bottom: 3.375rem;
  position: relative;
  text-decoration: none;
  background: black;
  height: 410px;
  width: 100%; }
  [data-component="banners"] .figure [data-component="text"],
  [data-component="banners"] figure [data-component="text"] {
    color: #FFFFFF;
    line-height: 1.15; }
    [data-component="banners"] .figure [data-component="text"] p,
    [data-component="banners"] figure [data-component="text"] p {
      font-size: 16px; }
    [data-component="banners"] .figure [data-component="text"] h3,
    [data-component="banners"] figure [data-component="text"] h3 {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700; }
  [data-component="banners"] .figure .image,
  [data-component="banners"] figure .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    [data-component="banners"] .figure .image img,
    [data-component="banners"] figure .image img {
      height: 100%;
      object-fit: cover;
      width: 100%; }
  [data-component="banners"] .figure .widget-bottom,
  [data-component="banners"] figure .widget-bottom {
    position: absolute;
    left: 30px;
    bottom: 40px; }
  [data-component="banners"] .figure .text-top, [data-component="banners"] .figure .text-bottom,
  [data-component="banners"] figure .text-top,
  [data-component="banners"] figure .text-bottom {
    position: absolute;
    max-width: 100%; }
  [data-component="banners"] .figure .text-top,
  [data-component="banners"] figure .text-top {
    top: 40px;
    left: 30px;
    width: 80%; }
  [data-component="banners"] .figure .text-bottom,
  [data-component="banners"] figure .text-bottom {
    bottom: 124px;
    left: 30px;
    width: 132px; }
  @media (max-width: 768px) {
    [data-component="banners"] .figure [data-component="button"] .btn,
    [data-component="banners"] figure [data-component="button"] .btn {
      padding: 20px; } }
  @media (max-width: 667px) {
    [data-component="banners"] .figure,
    [data-component="banners"] figure {
      height: 425px; } }
  @media (max-width: 414px) {
    [data-component="banners"] .figure,
    [data-component="banners"] figure {
      margin: 10px 0 40px; } }

[data-component="material"] {
  margin-bottom: 5.313rem; }
  @media (max-width: 414px) {
    [data-component="material"] {
      margin-bottom: 45px; } }
  [data-component="material"]:last-of-type {
    margin-bottom: 0; }
  [data-component="material"] [data-component="text"] h4 {
    font-weight: 800; }
  [data-component="material"] .inner-content {
    font-family: "Poppins", sans-serif; }
    [data-component="material"] .inner-content > * {
      display: flex;
      align-items: center;
      font-size: 1.125em;
      line-height: 1.6;
      transition: .3s; }
      [data-component="material"] .inner-content > *:hover {
        opacity: .75; }
      [data-component="material"] .inner-content > *:before {
        content: "";
        display: block;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 2rem; }
      @media (max-width: 414px) {
        [data-component="material"] .inner-content > * {
          align-items: flex-start;
          margin: .75rem .25rem 2rem; } }
    [data-component="material"] .inner-content.pdf {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -.25rem; }
      [data-component="material"] .inner-content.pdf > * {
        width: 48%;
        margin: .75rem .25rem;
        flex-basis: auto; }
        [data-component="material"] .inner-content.pdf > *:before {
          background-image: url("../img/pdf.png");
          min-width: 35px;
          min-height: 40px;
          width: 35px;
          height: 40px; }
      @media (max-width: 414px) {
        [data-component="material"] .inner-content.pdf {
          display: block;
          margin: 0; }
          [data-component="material"] .inner-content.pdf > * {
            width: auto;
            margin: 0 0 2rem 0; } }
    [data-component="material"] .inner-content.img > * {
      margin: .75rem 0; }
      [data-component="material"] .inner-content.img > *:before {
        background-image: url("../img/picture.png");
        min-width: 35px;
        min-height: 40px;
        width: 35px;
        height: 40px; }
    [data-component="material"] .inner-content.data > * {
      margin: .75rem 0; }
      [data-component="material"] .inner-content.data > *:before {
        background-image: url("../img/card.png");
        min-width: 35px;
        min-height: 40px;
        width: 35px;
        height: 40px; }

[data-component="table"] {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125em;
  border: 1px solid #F3F3F3; }
  @media (max-width: 1024px) {
    [data-component="table"] {
      font-size: 1em; } }
  @media (max-width: 768px) {
    [data-component="table"] {
      border: 1px solid #FFFFFF; } }
  [data-component="table"].specification td:nth-of-type(odd) {
    width: 25%;
    font-weight: 700; }
  [data-component="table"].specification td:nth-of-type(even) {
    font-weight: 400; }
  [data-component="table"].dimensions td:nth-of-type(odd) {
    font-weight: 700; }
  [data-component="table"].dimensions td:nth-of-type(even) {
    font-weight: 400;
    padding-left: 5px;
    width: 23%; }
  [data-component="table"] th, [data-component="table"] td {
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    border-collapse: collapse;
    padding: 15px 0 15px 24px; }
    @media (max-width: 768px) {
      [data-component="table"] th, [data-component="table"] td {
        padding: 15px 0;
        border-top: none; }
        [data-component="table"] th:first-of-type, [data-component="table"] td:first-of-type {
          padding-right: 20px; } }
  [data-component="table"] caption {
    padding: 25px 30px;
    background-color: #3B3B3B;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 700;
    text-align: center; }
    @media (max-width: 1024px) {
      [data-component="table"] caption {
        font-size: 20px; } }
    @media (max-width: 768px) {
      [data-component="table"] caption {
        display: none; } }
  @media (max-width: 414px) {
    [data-component="table"].dimensions, [data-component="table"].specification {
      display: block; }
      [data-component="table"].dimensions tr, [data-component="table"].specification tr {
        display: flex;
        flex-wrap: wrap; }
        [data-component="table"].dimensions tr > *:nth-of-type(odd), [data-component="table"].dimensions tr > *:nth-of-type(even), [data-component="table"].specification tr > *:nth-of-type(odd), [data-component="table"].specification tr > *:nth-of-type(even) {
          width: 50%; } }

.table-tabs {
  display: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  margin-bottom: 2rem; }
  @media (max-width: 768px) {
    .table-tabs {
      display: flex; }
      .table-tabs > .label {
        flex: 1;
        padding: 10px;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        background-color: #ADADAD; }
        .table-tabs > .label.active {
          background-color: #312D3B; }
        .table-tabs > .label:first-of-type {
          margin-right: 1rem; } }

[data-component="advert-area"] {
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #F5F5F5; }
  [data-component="advert-area"]:first-child {
    margin-top: -3rem; }
  [data-component="advert-area"] [data-component="image"] {
    margin-bottom: 1.5rem; }
  [data-component="advert-area"] [data-component="text"] {
    font-family: 'Open Sans', sans-serif; }
    [data-component="advert-area"] [data-component="text"] h5 {
      font-weight: 700;
      margin-bottom: .5rem;
      text-transform: uppercase; }
    [data-component="advert-area"] [data-component="text"] p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: .5rem; }
      [data-component="advert-area"] [data-component="text"] p:last-of-type {
        margin-bottom: 0; }
    [data-component="advert-area"] [data-component="text"] a {
      font-weight: 400;
      font-style: italic;
      color: #FFC600; }

[data-component="filter"] {
  position: absolute;
  right: 0;
  top: -85px;
  padding-right: 34px; }
  @media (max-width: 414px) {
    [data-component="filter"] {
      top: -65px; } }
  [data-component="filter"]:after {
    content: "";
    position: absolute;
    top: 33px;
    right: 0;
    width: 14px;
    height: 14px;
    background-image: url(../img/svg/rectangle.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: .3s;
    z-index: 1; }
  [data-component="filter"] .label {
    font-size: 16px;
    line-height: 19px;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 6px; }
    @media (max-width: 414px) {
      [data-component="filter"] .label {
        margin-bottom: 3px; } }
  [data-component="filter"] .title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 800;
    cursor: pointer; }
  [data-component="filter"] .selection {
    border: 1px solid #979797;
    background-color: #FFFFFF;
    position: absolute;
    top: calc(100% + 20px);
    right: -23px;
    z-index: 15;
    padding: 30px 30px 15px 30px;
    width: 210px;
    transform: translatey(15px);
    opacity: 0;
    visibility: hidden;
    transition: .4s; }
    [data-component="filter"] .selection:after {
      content: "";
      position: absolute;
      top: -9px;
      right: 18px;
      transform: rotate(-135deg);
      border: 9px solid;
      border-color: transparent #fff #fff transparent;
      box-shadow: 1px 1px 0 #979797; }
    @media (max-width: 414px) {
      [data-component="filter"] .selection {
        right: -15px; }
        [data-component="filter"] .selection:after {
          top: -9px;
          right: 12px; } }
  [data-component="filter"].active .selection {
    opacity: 1;
    visibility: visible;
    transform: translatey(0); }
  [data-component="filter"] [data-component=category-list] {
    margin-bottom: 0; }
    [data-component="filter"] [data-component=category-list] .list {
      font-size: 22px;
      font-weight: 300;
      color: #5E5E5E; }
      [data-component="filter"] [data-component=category-list] .list > * {
        padding: 0;
        border-bottom: 1px solid #F5F5F5;
        color: inherit; }
        [data-component="filter"] [data-component=category-list] .list > *:last-child {
          border-bottom: 0; }
        [data-component="filter"] [data-component=category-list] .list > * > * {
          color: inherit; }
          [data-component="filter"] [data-component=category-list] .list > * > *.active {
            font-weight: 800; }

[data-component="preloader"] {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  transition: 1s;
  transition-delay: 1s;
  display: flex;
  align-items: center;
  justify-content: center; }
  [data-component="preloader"].away {
    filter: blur(20px);
    opacity: 0;
    pointer-events: none; }
  [data-component="preloader"] .container {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
  [data-component="preloader"] .item {
    width: 33px;
    height: 33px;
    position: relative;
    display: block;
    transform-origin: -50% center;
    border-radius: 15%; }
    [data-component="preloader"] .item:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(255, 198, 0, 0.2);
      border-radius: 15%; }
    [data-component="preloader"] .item:nth-child(1) {
      animation: slide 1.5s ease-in-out infinite alternate; }
      [data-component="preloader"] .item:nth-child(1):after {
        animation: color-change 1.5s ease-in-out infinite alternate; }
    [data-component="preloader"] .item:nth-child(2) {
      animation: flip-1 1.5s ease-in-out infinite alternate; }
      [data-component="preloader"] .item:nth-child(2):after {
        animation: squidge-1 1.5s ease-in-out infinite alternate; }
    [data-component="preloader"] .item:nth-child(3) {
      animation: flip-2 1.5s ease-in-out infinite alternate; }
      [data-component="preloader"] .item:nth-child(3):after {
        animation: squidge-2 1.5s ease-in-out infinite alternate; }
    [data-component="preloader"] .item:nth-child(4) {
      animation: flip-3 1.5s ease-in-out infinite alternate; }
      [data-component="preloader"] .item:nth-child(4):after {
        animation: squidge-3 1.5s ease-in-out infinite alternate; }
    [data-component="preloader"] .item:nth-child(5) {
      animation: flip-4 1.5s ease-in-out infinite alternate; }
      [data-component="preloader"] .item:nth-child(5):after {
        animation: squidge-4 1.5s ease-in-out infinite alternate; }
    [data-component="preloader"] .item:nth-child(2):after {
      background-color: rgba(255, 198, 0, 0.8); }
    [data-component="preloader"] .item:nth-child(3):after {
      background-color: rgba(255, 198, 0, 0.6); }
    [data-component="preloader"] .item:nth-child(4):after {
      background-color: rgba(255, 198, 0, 0.4); }
    [data-component="preloader"] .item:nth-child(5):after {
      background-color: rgba(255, 198, 0, 0.2); }

@keyframes slide {
  0% {
    background-color: #FFC600;
    transform: translatex(0vw); }
  100% {
    background-color: rgba(255, 198, 0, 0.2);
    transform: translatex(calc(250px - (33px * 1.25))); } }

@keyframes color-change {
  0% {
    background-color: #FFC600; }
  100% {
    background-color: rgba(255, 198, 0, 0.2); } }

@keyframes flip-1 {
  0%, 15% {
    transform: rotate(0); }
  35%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-1 {
  5% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  15% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  25%, 20% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  55%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  40% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-2 {
  0%, 30% {
    transform: rotate(0); }
  50%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-2 {
  20% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  30% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  40%, 35% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  70%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  55% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-3 {
  0%, 45% {
    transform: rotate(0); }
  65%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-3 {
  35% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  45% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  55%, 50% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  85%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  70% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-4 {
  0%, 60% {
    transform: rotate(0); }
  80%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-4 {
  50% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  60% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  70%, 65% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  100%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  85% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

[data-component="brands"] {
  display: flex;
  align-items: center;
  margin: 50px -35px; }
  [data-component="brands"] > * {
    /*margin: 0 35px;
        max-width: 8%;*/
    margin: 0 14.375px;
    max-width: 10.49%; }
  @media (max-width: 1199px) {
    [data-component="brands"] {
      flex-wrap: wrap;
      justify-content: center; }
      [data-component="brands"] > * {
        flex-basis: auto;
        width: 10%;
        margin: 35px;
        max-width: 100%; } }
  @media (max-width: 768px) {
    [data-component="brands"] {
      margin: 3rem 0; }
      [data-component="brands"] > * {
        width: 15%; } }
  @media (max-width: 667px) {
    [data-component="brands"] {
      margin: 3rem 0 5rem; } }
  @media (max-width: 414px) {
    [data-component="brands"] {
      margin: 0; }
      [data-component="brands"] > * {
        width: 30%; } }

[data-component="category-list"] {
  margin-bottom: 84px; }
  [data-component="category-list"] .title {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 14px; }
  [data-component="category-list"] .list {
    font-size: 18px;
    font-weight: 800;
    text-transform: capitalize; }
    [data-component="category-list"] .list > * {
      padding: 10px 0 5px;
      border-bottom: 1px solid #F5F5F5; }
      [data-component="category-list"] .list > *:last-child {
        border-bottom: 0; }
      [data-component="category-list"] .list > * > * {
        display: inline-flex;
        padding: 0 10px 0 15px;
        line-height: 3;
        color: #929292;
        transition: .3s; }
        [data-component="category-list"] .list > * > *:hover {
          color: #3B3B3B; }
        [data-component="category-list"] .list > * > *.active {
          color: #3B3B3B; }
          [data-component="category-list"] .list > * > *.active:before {
            content: "";
            transform: translatex(-30px);
            border-left: 5px solid #ffe846; }
