$duration: 1.5s;
$container-size: 250px;
$box-size: 33px;
$box-border-radius: 15%;

[data-component="preloader"] {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background-color: rgba(white, 1);
    z-index: 1000;
    transition: 1s;
    transition-delay: 1s;
    display: flex;
    align-items: center;
    justify-content: center;

    &.away {
        filter: blur(20px);
        opacity: 0;
        pointer-events: none;
    }

    .container
    {
        width: $container-size;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .item
    {
        width: $box-size;
        height: $box-size;
        position: relative;
        display: block;
        transform-origin: -50% center;
        border-radius: $box-border-radius;

        &:after
        {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: transparentize($color_yellow, .8);
            border-radius: $box-border-radius;
        }

        &:nth-child(1)
        {
            animation: slide $duration ease-in-out infinite alternate;
            &:after{ animation: color-change $duration ease-in-out infinite alternate; }
        }

        @for $i from 1 to 5
        {
            &:nth-child(#{$i + 1})
            {
                animation: flip-#{$i} $duration ease-in-out infinite alternate;
                &:after{ animation: squidge-#{$i} $duration ease-in-out infinite alternate; }
            }
        }

        &:nth-child(2):after{ background-color: transparentize($color_yellow, .2); }
        &:nth-child(3):after{ background-color: transparentize($color_yellow, .4); }
        &:nth-child(4):after{ background-color: transparentize($color_yellow, .6); }
        &:nth-child(5):after{ background-color: transparentize($color_yellow, .8); }
    }
}

@keyframes slide
{
    0% { background-color: $color_yellow; transform: translatex(0vw); }
    100% { background-color: transparentize($color_yellow, .8); transform: translatex(calc(250px - (33px * 1.25))); }
}

@keyframes color-change
{
    0% { background-color: $color_yellow; }
    100% { background-color: transparentize($color_yellow, .8); }
}

@for $i from 1 to 5
{
    @keyframes flip-#{$i} {
    0%, #{$i * 15}% { transform: rotate(0); }
    #{$i * 15 + 20}%, 100% { transform: rotate(-180deg); }
}

@keyframes squidge-#{$i}
{
#{$i * 15 - 10}% { transform-origin: center bottom; transform: scalex(1) scaley(1);}
#{$i * 15}% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7);}
#{$i * 15 + 10}%, #{$i * 15 + 5}% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4);}
#{$i * 15 + 40}%, 100% { transform-origin: center top; transform: scalex(1) scaley(1);}
#{$i * 15 + 25}% { transform-origin: center top; transform: scalex(1.3) scaley(0.7);}
}
}
