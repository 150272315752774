[data-component="button"] {
    display: inline-block;
    font-size: 16px;

    .btn {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        color: $color_text;
        font-size: .875em;
        font-weight: 700;
        letter-spacing: 3px;
        background-color: $color_yellow;
        box-shadow: 0 10px 20px 0 rgba(black, .15);
        text-transform: uppercase;
        border-radius: 40px;
        padding: 1.7em 2.5em;
        outline: none;
        border: none;
        cursor: pointer;
        transition: .3s;
        -webkit-appearance: none;
        text-align: center;
        justify-content: center;
        user-select: none;

        &:hover {
            background-color: #eab706;
        }
    }
    @media(max-width: $mobile) {
        // display: block;
        //
        // .btn {
        //     display: flex;
        //     margin: 0 auto;
        //     width: 100%;
        // }
    }
}
