[data-template="aboutus-section-2"] {
    padding: 10rem 5rem 5rem;
    margin: 10px 0;
    font-size: 16px;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: linear-gradient(to bottom,rgba(white,.95) -30%, white 70%),url(../img/aboutus-section-2.png);

    .inner {
        max-width: 780px;
        margin: 0 auto;
    }

    [data-component="image"] {
        margin-bottom: 3rem;
    }

    [data-component="text"] {
        p {
            font-size: 1.125em;
        }
        h4 {
            font-weight: 800;
        }
        [data-component="image"] {
            float: left;
            margin: 0 3rem 3rem 0
        }
    }

    .product {
        &-show-list {
            display: flex;
            margin: 0 -35px;
            justify-content: center;

            @media(max-width: $mobile) {
                flex-wrap: wrap;
                margin: 0;
            }

            > * {
                width: 33%;
                flex-basis: auto;
                margin: 0 35px;
                transition: .3s;

                @media(max-width: $mobile) {
                    width: 33%;
                    margin: 12px 21px;
                }


                &:hover {
                    opacity: .75;
                }
            }
        }
    }

    @media(max-width: 700px) {

        [data-component="text"] {
            [data-component="image"] {
                max-width: 200px;
            }
        }
    }

    @media(max-width: $mobile_large) {

        [data-component="text"] {
            [data-component="image"] {
                max-width: none;
                float: none;
                margin: 0 0 3rem 0
            }
        }
    }

    @media(max-width: $mobile) {
        padding: 4rem 24px;
    }
}
