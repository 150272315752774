[data-template="contactus"] {
    text-align: center;
    background-image: url("../img/home-section-3.png");
    background-size: cover;
    font-size: 16px;

    .container {
        padding: 15rem 3rem;

        @media(max-width: $laptop_small) {
            padding: 10rem 3rem;
        }
        @media(max-width: $laptop_small) {
            padding: 40px 20px 60px;
        }
    }

    [data-component="text"] {
        color: $color_white;
    }

    form {

        max-width: 620px;
        margin: 3rem auto 0;

        .input {
            &-area {
                display: flex;
                margin: 0 -1rem;

                @media(max-width: $mobile) {
                    display: block;
                    margin: 0;
                }
            }

            &-text {
                width: 45%;
                margin: 0 1rem;
                flex-basis: auto;
                background-color: $color_white;
                border-radius: 40px;
                overflow: hidden;
                padding: 20px;

                @media(max-width: $mobile) {
                    width: 100%;
                    margin: 0;
                    margin-bottom:2rem;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                input {
                    max-width: 100%;
                    width: 100%;

                    @media(max-width: $mobile) {
                        width: 100%;

                    }
                }
            }
        }

        textarea {
            padding: 20px;
            margin-top: 2rem;
            margin-bottom: 3.125rem;
            background-color: $color_white;
            border-radius: 10px;
            resize: none;
            max-width: 100%;
        }

        .input-text input, textarea {
            font-family: 'Montserrat', sans-serif;
            font-size: 0.875em;
            border: none;
            outline: none;

            &::-webkit-input-placeholder {
                font-family: 'Montserrat', sans-serif;
                font-size: 1em;
            }

            &::-moz-placeholder {
                font-family: 'Montserrat', sans-serif;
                font-size: 1em;
            }

            &:-ms-input-placeholder {
                font-family: 'Montserrat', sans-serif;
                font-size: 1em;
            }

            &:-moz-placeholder {
                font-family: 'Montserrat', sans-serif;
                font-size: 1em;
            }
        }
    }
}
