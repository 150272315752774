[data-component="webnavigation"] {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media(max-width: 1299px) {
        display: block;
        border-top: 2px solid #f2f2f2;
        padding-top: 2rem;
        margin: 2rem 0 4rem;

        > * {
            display: block;
            margin-bottom: 2rem;
        }
    }

    a {
        font-size: 0.875em;
        text-decoration: none;
        color: $color_black;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 2rem;
        transition: .3s;

        &:last-of-type {
            margin-right: 4rem;
        }

        &:hover {
            opacity: .5;
        }

        &.active {
            pointer-events: none;
            font-size: .875em;
            font-weight: 800;
        }
    }
}
