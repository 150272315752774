[data-template="home-section-4"] {
    text-align: center;
    background-image: url("../img/home-section-4.png");
    background-size: cover;
    font-size: 16px;

    .container {
        padding: 15rem 3rem;

        @media(max-width: $laptop_small) {
            padding: 10rem 3rem;
        }

        @media(max-width: $mobile) {
            padding: 80px 40px;;
        }
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        margin: 5rem auto -2rem;
        padding-left: 10rem;
        text-align: left;
        overflow-y: auto;

        @extend %scroll;

        @media(max-width: $laptop_large) {
            padding-left: 3rem;
        }

        @media(max-width: $laptop_small) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;
            padding: 0;
            max-height: none;
        }

        @media(max-width: 700px) {
            display: block;
            max-height: 300px;
        }

        > * {
            width: 33%;
            padding: 0 1rem 2rem 1rem;

            @media(max-width: $laptop_small) {
                width: 100%;
            }

            span {
                font-family: 'Poppins', sans-serif;
                flex-basis: auto;
                display: flex;
                align-items: center;
                font-size: 1.5em;
                color: $color_text;
                font-weight: 700;

                &:before {
                    content: "";
                    display: block;
                    width: 60px; height: 60px;
                    background-image: url("../img/icon-location.png");
                    background-size: cover;
                    margin-right: 2.5rem;
                }

                @media(max-width: $laptop_large) {

                    &:before {
                        width: 40px; height: 40px;
                        min-width: 40px; min-height: 40px;
                    }
                }

                @media(max-width: $portrait_large) {
                    font-size: 1.25em;
                }

                @media(max-width: $mobile_large) {
                    font-size: 1.15em;

                    &:before {
                        width: 1.25em; height: 1.25em;
                        min-width: 1.25em; min-height: 1.25em;
                    }
                }

                @media(max-width: $mobile) {
                    font-size: 1em;

                    &:before {
                        width: 30px; height: 30px;
                    }
                }
            }
        }
    }
}
