[data-component="table"] {
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125em;
    border: 1px solid #F3F3F3;

    @media(max-width: $laptop_small) {
        font-size: 1em;
    }

    @media(max-width: $portrait_large) {
        border: 1px solid $color_white;
    }

    &.specification {
        td {
            &:nth-of-type(odd) {
                width: 25%;
                font-weight: 700
            }
            &:nth-of-type(even) {
                font-weight: 400
            }
        }

    }

    &.dimensions {
        td {

            &:nth-of-type(odd) {
                font-weight: 700;
            }

            &:nth-of-type(even) {
                font-weight: 400;
                padding-left: 5px;
                width: 23%;
            }
        }


    }

    th, td {
        border-top: 1px solid #F3F3F3;
        border-bottom: 1px solid #F3F3F3;
        border-collapse: collapse;
        padding: 15px 0 15px 24px;

        @media(max-width: $portrait_large) {
            padding: 15px 0;
            border-top: none;

            &:first-of-type {
                padding-right: 20px;
            }
        }
    }

    caption {
        padding: 25px 30px;
        background-color: $color_black;
        color: $color_white;
        font-size: 24px;
        font-weight: 700;
        text-align: center;

        @media(max-width: $laptop_small) {
            font-size: 20px;
        }


        @media(max-width: $portrait_large) {
            display: none;
        }
    }

    @media(max-width: $mobile) {
        &.dimensions, &.specification {
            display: block;
            tr {
                display: flex;
                flex-wrap: wrap;

                > * {

                    &:nth-of-type(odd), &:nth-of-type(even) {
                        width: 50%;
                    }
                }
            }
        }
    }
}

.table-tabs {
    display: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    margin-bottom: 2rem;

    @media(max-width: $portrait_large) {
        display: flex;


        > .label {
            flex: 1;
            padding: 10px;
            color: $color_white;
            text-transform: uppercase;
            text-align: center;
            font-weight: 600;
            background-color: #ADADAD;

            &.active {
                background-color: $color_text;
            }

            &:first-of-type {
                margin-right: 1rem;
            }

        }
    }
}
