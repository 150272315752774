[data-template="single-section-2"] {
    padding: 7rem 5rem;
    margin: 10px 0;
    font-size: 16px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(to bottom, rgba(white,.9), rgba(white,.9)),url(../img/aboutus-section-2.png);

    @media(max-width: $mobile) {
        padding: 5rem 24px;
    }

    .inner {
        max-width: 1180px;
        margin: 0 auto;
    }

    [data-component="material"] {
        padding-left: 150px;
        font-weight: 600;
        margin-top: 5rem;

        @media(max-width: $laptop_small) {
            padding-left: 75px
        }

        @media(max-width: $portrait_large) {
            padding-left: 0;
            margin-top: 24px;
        }

    }

    [data-component="text"] {
        text-align: center;

        h2 {
            text-align: center;
        }
    }
}
