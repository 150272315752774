[data-widget="accordion"] {
    font-size: 16px;
    box-shadow: 6px 1px 13px 0 rgba(0,0,0,0.09);
    margin: 2.5rem 0;
    cursor: pointer;
    transition: .3s;

    &:first-of-type {
        margin-top: 0;
    }

    &:hover {
        opacity: .75;
    }

    &.accordion-active {

        &:hover {
            opacity: 1;
        }
    }

    $accordion_padding: 25px;

    .title {
        font-size: 24px;
        color: #4E4E4E;
        font-weight: 800;
        border-left: 8px solid $color_yellow;
        padding: $accordion_padding  60px $accordion_padding $accordion_padding;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            right: $accordion_padding;
            width: $accordion_padding;
            height: $accordion_padding;
            background-image: url("../img/svg/shape.svg");
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            transition: .3s;
        }

        @media(max-width: $mobile) {
            font-size: 18px;
        }
    }

    .content {
        display: none;
        border-left: 8px solid $color_grey;
        margin-top: -$accordion_padding;

        @media(max-width: $mobile) {
            border: none;
        }

        &-inner {
            padding: 0 $accordion_padding $accordion_padding;

            @media(max-width: $mobile) {
                padding-left: 0;
                margin-top: 3rem;
            }

            &:before {
                content: "";
                display: block;
                width: 100%;
                padding: 1px;
                margin-bottom: $accordion_padding;
                background-color: $color_grey;
                margin-top: $accordion_padding;
            }
        }
    }

    [data-component="text"] {

        h5 {
            font-weight: 800;
        }

        p {
            font-size: 1.125em;

            @media(max-width: $mobile) {
                line-height: 1.35
            }
        }
    }

    &.accordion-active  {
        @media(max-width: $mobile) {
            box-shadow: none;
        }
        .title {
            &:before {
                transform: rotate(180deg);
            }
        }

        .content {
            display: block;
        }
    }
}
