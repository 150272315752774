[data-component="text"] {
    font-size: 16px;
    color: $color_text;

    h1, h2 {
        font-weight: 800;
    }

    h1 {

    }

    h2 {
        text-transform: uppercase;
        font-size: 3em;
        margin-bottom: 1.688rem;
        line-height: 1.1;

        @media(max-width: $portrait_large) {
            font-size: 2em
        }
        @media(max-width: $mobile) {
            font-size: 1.5em
        }
    }

    h4 {
        text-transform: uppercase;
        font-size: 1.5em;
        margin-bottom: 1.688rem;
        line-height: 1.1;

    }

    h5 {
        font-weight: 600;
        font-size: 1.125em;
        margin-bottom: 1em;
        line-height: 1.35;
    }


    p {
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;

        margin-bottom: 2.75rem;

        @media(max-width: $portrait_large) {
            font-size: 1.5em;
            line-height: 1.75;
        }
        @media(max-width: $mobile) {
            font-size: 1em;
            line-height: 2;
        }
    }

    .featured-title {
        display: flex;
        align-items: center;
        font-size: 1.5em;
        text-transform: capitalize;
        color: $color_text;
        font-weight: 700;
        text-align: center;

        span {
            margin: 0 1.25rem;
        }

        @media(max-width: $mobile) {
            display: block;

            margin-top: 55px;
            margin-bottom: 40px;

            &:after {
                display: none;
            }
        }

        &:before, &:after {
            content: "";
            height: 1px;
            background-color: $color_text;
            flex: 1;

            @media(max-width: $mobile) {
                display: none;
            }
        }
    }

    b {
        font-weight: 600;
    }

    a {
        color: inherit;
        text-decoration: none;
        font-weight: 600;
    }
}
