[data-template="msds-section-1"] {
    padding:  6.375rem 2rem;
    font-size: 16px;

    .grid.aside {
        .left {
            @media(max-width: 800px) {
                padding-top: 3rem;
            }
        }
    }

    .inner {
        max-width: 1160px;
        margin: 0 auto;

        [data-component="text"] {
            h2 {
                font-weight: 500;
            }

            h4 {
                font-weight: 700;
            }

            b {
                font-weight: 700
            }
        }

        &-content {
            margin-bottom: 5rem;
        }
    }
}
