[data-component="image"] {

    .swiper-container {
        margin:0 auto;
        position:relative;
        overflow:hidden;
        list-style:none;
        padding:0;
        z-index:1;

        &-fade {

            .swiper-slide {
                pointer-events: none;
                -webkit-transition-property: opacity;
                -o-transition-property: opacity;
                transition-property: opacity;

                .swiper-slide {
                    pointer-events: none;
                }

                &-active {
                    pointer-events: auto;

                    .swiper-slide-active {
                        pointer-events: auto;
                    }
                }
            }
        }

        .swiper-notification {
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            opacity: 0;
            z-index: -1000;
        }
    }

    .swiper-wrapper {
        position:relative;
        width:100%;
        height:100%;
        z-index:1;
        display:-webkit-box;
        display:-webkit-flex;
        display:-ms-flexbox;
        display:flex;
        -webkit-transition-property:-webkit-transform;
        transition-property:-webkit-transform;
        -o-transition-property:transform;
        transition-property:transform;
        transition-property:transform,-webkit-transform;
        -webkit-box-sizing:content-box;
        box-sizing:content-box
    }

    .swiper-pagination-lock {
        display: none;
    }

    .swiper-pagination.swiper-pagination-hidden {
        opacity: 0;
    }
    .swiper-button-lock {
        display: none;
    }

    .swiper-slide{
        -webkit-flex-shrink:0;
        -ms-flex-negative:0;
        flex-shrink:0;
        width:100%;
        height:100%;
        position:relative;
        -webkit-transition-property:-webkit-transform;
        transition-property:-webkit-transform;
        -o-transition-property:transform;
        transition-property:transform;
        transition-property:transform,-webkit-transform
    }

    &.desktop {
        max-width: 200px;

        @media(max-width: $portrait_large) {
            display: none;
        }
    }


    &[data-align="center"] {
        text-align: center;
        float: none !important;

        img {
            width: auto;
        }
    }

    img {
        max-width: 100%;
        width: 100%;
        vertical-align: middle;
    }
}
