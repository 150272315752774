[data-component="advert-area"] {

    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid $color_grey;

    &:first-child {
        margin-top: -3rem;
    }

    [data-component="image"] {
        margin-bottom: 1.5rem;
    }

    [data-component="text"] {
        font-family: 'Open Sans', sans-serif;

        h5 {
            font-weight: 700;
            margin-bottom: .5rem;
            text-transform: uppercase;
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: .5rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        a {
            font-weight: 400;
            font-style: italic;
            color: $color_yellow;
        }
    }
}
