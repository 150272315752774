[data-template="retailers-section-1"] {
    padding:  6.375rem 2rem 3.375rem;

    .inner {
        max-width: 1160px;
        margin: 0 auto;

        [data-component="text"] {
            h2 {
                display: block;

                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: $color_grey;
                    margin: 2.5rem 0;
                }
            }
        }

        [data-component="button"] {
            width: 100%;
            text-align: center;

            @media(max-width: $mobile) {
                margin: 0 0 30px;
            }
        }
    }

    .retailers-list {
        display: flex;
        margin: 5rem -1rem;
        justify-content: center;
        flex-wrap: wrap;

        .item {
            width: 17%;
            flex-basis: auto;
            margin: 1rem;
        }

        @media(max-width: $portrait_large) {
            .item {
                width: 21%;
            }
        }

        @media(max-width: $mobile) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            margin: 0;

            .item {
                width: 100%;
                margin: 0;
            }
        }
    }
}
