[data-template="single-section-3"] {
    padding: 7rem 5rem 0;

    @media(max-width: $mobile) {
        padding: 5rem 0;
    }

    .inner {
        max-width: 1160px;
        margin: 0 auto;
    }

    [data-component="text"] {
        h2 {
            text-align: center;
        }
    }

    [data-component="products-list"] {
        .figure,
        figure {
            width: 22%;
            margin: 0 1rem 0.875rem;

            @media(max-width: $laptop_small) {
                .description {
                    transform: translatey(160px);
                }
            }

            @media(max-width: $portrait_large) {
                width: 100%;
                margin: auto;
                .description {
                    transform: translatey(140px);
                }
            }

            @media(max-width: $mobile) {
                width: 250px;
                margin: 0 auto;

                .description {
                    transform: translatey(120px);
                }
            }
        }
    }
}
