[data-template="brand-section-2"] {
    padding: 7rem 5rem 3rem;
    margin: 10px 0;
    font-size: 16px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(to bottom, rgba(white,.9), rgba(white,.9)),url(../img/aboutus-section-2.png);

    @media(max-width: $mobile) {
        padding: 4rem 24px;
    }

    .inner {
        max-width: 780px;
        margin: 0 auto;
    }

    [data-component="image"] {
        margin-bottom: 3rem;
    }

    [data-component="text"] {
        p {
            font-size: 1.125em;
        }
        h4 {
            font-weight: 700;
        }
        [data-component="image"] {
            float: left;
            margin: 0 3rem 3rem 0
        }
    }

    .product {
        &-show-list {
            display: flex;
            margin: 0 -35px;

            > * {
                width: 33%;
                flex-basis: auto;
                margin: 0 35px;
                transition: .3s;

                &:hover {
                    opacity: .75;
                }
            }
        }
    }

}
