[data-widget="product-categories"] {
    font-size: 16px;
    margin: 8.25rem 0 4.4rem;
    @media(max-width: $mobile) {
        margin: 5rem 0 3rem;
    }

    .product {
        &-categories {

            &_navigation {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 85%;
                margin: 0 auto;

                @media(max-width: $laptop_small) {
                    width: 100%;
                }

                @media(max-width: $mobile) {
                    width: 100%;
                }


                .item {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    color: $color_white;
                    height: 30px;
                    font-weight: 600;
                    letter-spacing: 3px;
                    padding: 1rem 2rem;
                    text-transform: uppercase;
                    font-size: .875em;
                    margin-bottom: 1.7rem;
                    cursor: pointer;
                    transition: .3s;
                    @media(max-width: $mobile) {
                        margin-bottom: 1rem;
                    }
                    &:hover:not(.active) {
                        color: $color_yellow;
                    }

                    &.active {
                        border-radius: 40px;
                        color: $color_text;
                        background-color: $color_yellow;
                        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.5);
                    }
                }

                .brake {
                    width: 100%;
                }
            }
        }
    }
}

.product {
    &-categories {

        &__item {
            width: 190px;
            margin: 0 22px 44px;

            &s {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 4.3rem;
            }
        }

        &__wrap {
            max-width: 1100px;
            margin: 0 auto;
        }
    }
}