[data-template="single-section-1"] {
    padding:  6.375rem 2rem;
    font-size: 16px;

    @media(max-width: $portrait_large) {
        padding: 8.375rem 3rem 0rem;
    }

    @media(max-width: $portrait_large) {
        padding: 8.375rem 5rem 0;
    }

    @media(max-width: $mobile) {
        padding: 5rem 24px 0;
    }

    .inner {
        max-width: 1160px;
        margin: 0 auto;

        .header-mobile {
            display: none;

            @media(max-width: $portrait_large) {
                display: block;
            }
        }

        .header-desktop {
            @media(max-width: $portrait_large) {
                h2 {
                    display: none;
                }
            }
        }

        .grid.col-2:not(.table) {
            align-items: flex-end;

            @media(max-width: $portrait_large) {
                align-items: flex-start;
            }
            @media(max-width: $mobile) {
                .mobile-change {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    [data-component="button"] {
                        display: inline-flex;
                        width: auto;
                        order: 1;
                        margin-bottom: 3rem;

                        .btn {
                            width: auto;
                        }
                    }
                    [data-component="text"] {
                        order: 2;
                    }
                }
            }

        }

        .grid.col-2.table {
            margin-top: 10rem;
            align-items: flex-start;

            @media(max-width: $mobile) {
                margin: 3rem 0;
            }

            [data-component="text"] {
                p {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
