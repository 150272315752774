[data-template="aboutus-section-1"] {
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        background-image: url('../img/old_building.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: -8%;
        top: 0;
        width: 60%;
        height: 200%;
        z-index: -1;

        @media(max-width: $laptop_small) {
            right: -6%;
        }
    }

    @media(max-width: $portrait_large) {

        &:after {
            display: none;
        }
    }

    .container {
        padding:  6.375rem 2rem 0 3rem;

        @media(max-width: $mobile_large) {
            padding: 0 2rem 2rem 2rem;
        }
    }

    .row {
        padding: 0 1rem 9.688rem 7rem;
        display: flex;

        > * {
            flex: 1;
        }

        @supports (display: grid) {
            display: grid;
            grid-template-columns: 34% 335px 1fr;
            grid-column-gap: 5.5rem;
        }

        @media(max-width: $laptop_small) {
            padding: 2rem;
            grid-template-columns: repeat(2, 1fr);

            text-align: center;

            [data-component="text"] {
                width: 80%;
                margin: 0 auto 5rem;

                br {
                    display: none;
                }
            }
        }

        @media(max-width: $portrait_large) {
            padding: 3rem;
            display: block;
            text-align: center;

            [data-component="text"] {
                width: 80%;
                margin: 0 auto 5rem;

                br {
                    display: none;
                }
            }
        }

        @media(max-width: $mobile) {
            padding: 0;

            [data-component="text"] {
                width: 100%;
                margin: 0 auto 5rem;
            }
        }

        .item {
            &:nth-of-type(3) {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                [data-component="image"] {
                    max-width: 205px;
                    width: 100%;
                }
            }

            @media(max-width: $laptop_small) {

                &:nth-of-type(3) {
                    display: none;
                }

                [data-component="image"] {
                    max-width: 300px;
                }
            }

            @media(max-width: $portrait_large) {

                &:nth-of-type(2), &:nth-of-type(3) {
                    display: none;
                }
            }
        }
    }

    .logoimage-mobile {
        position: relative;
        display: none;
        margin: 0 -2rem 5rem -2rem;
        padding-top: 100px;
        overflow: hidden;

        &:after {
            content: "";
            display: block;
            background-color: #ffc600;
            position: absolute;
            left: -30%;
            top: -40%;
            width: 130%;
            height: 130%;
            transform-origin: center center;
            transform: rotate(-7deg) skewx(7deg);
            z-index: -1;
        }

        [data-component="image"] {

            &.product {
                margin: 0 auto;
                max-width: 110px;
                width: 100%;
            }

            &.year {
                margin: 0 auto;
                max-width: 155px;
                width: 100%;
            }

        }

        @media(max-width: $mobile_large) {
            display: block;
        }
    }
}
