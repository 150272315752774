.grid {

    &.lefty {
        display: flex;
        border-top: 2px solid $color_grey;
        margin-top: 2rem;
        margin-bottom: 7rem;
        padding-top: 3.5rem;
        position: relative;

        .left {
            min-width: 280px;
            padding-right: 38px;
            padding-left: 15px;
            margin-right: 46px;
            border-right: 3px solid $color_grey;

            > * {
                &:last-child {
                    margin-bottom: 0;
                    padding: 0;
                    border-bottom: none;
                }
            }

        }

        .right {
            padding-left: 20px;
            width: 100%;
        }
    }

    &.aside {
        display: flex;
        border-top: 2px solid $color_grey;
        margin-top: 2rem;
        padding-top: 3.5rem;

        .left {
            width: 73%;
            margin-right: 4rem;
        }

        .right {
            width: 27%;
        }

        @media(max-width: $portrait_large) {
            display: block;

            margin-top: 0;
            padding-top: 0;

            .left, .right {
                width: 100%;
            }
        }

        @media(max-width: $mobile_large) {
            border-top: none;
        }
    }

    &.col-2 {
        display: flex;

        > .item {
            width: 50%;
            flex-basis: auto;

            &:first-of-type {
                margin-right: 5rem;

                @media(max-width: $portrait_large) {
                    margin-bottom: 7rem;
                }
            }
        }

        @media(max-width: $laptop_small) {
            &.table {

                > .item {
                    &:first-of-type {
                        margin-right: 2rem;
                    }
                }
            }
        }

        @media(max-width: $portrait_large) {
            display: block;
            width: 100%;

            > .item {
                width: 100%;
            }

            &.table {
                display: block;
                width: 100%;

                > .item {
                    margin: 0 0 3rem;
                    width: 100%;

                    &.mobile-disabled {
                        display: none;
                    }
                }
            }
        }
    }
}
