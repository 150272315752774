[data-widget="accordion-products"] {
    display: flex;
    margin-bottom: 40px;

    @media(max-width: 700px) {
        display: block;
    }

    .accordion {
        position: relative;
        width: 92%;
        height: 4rem;
        margin-right: 3rem;

        @media(max-width: $laptop_small) {
            height: 5rem;
        }

        @media(max-width: 800px) {
            height: 5rem;
            margin-bottom: 3rem
        }


        .container {
            position: absolute;
            left: 0; top: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            border: 1px solid $color_text;
            background-color: $color_white;
            padding: 23px 40px 20px;
            border-radius: 40px;
            height: 100%;
            overflow: hidden;
            user-select: none;

            cursor: pointer;

            > * {
                display: block;
                font-size: 1.125em;
                margin-bottom: 2rem;
                font-weight: 600;
                pointer-events: none;
                order: 2;
                z-index: 3;
                transition: .3s;
                cursor: pointer;

                @media(max-width: $mobile) {
                    font-size: 1em;
                    line-height: 1.25;
                }

                &:hover:not(.active) {
                    opacity: .5;
                }

                &.active {
                    order: 1;
                    border-bottom: 2px solid $color_grey;
                    padding-bottom: 2rem;
                }
            }
        }


        @media(max-width: $mobile) {
            height: 60px;
            margin-right: 0;
            margin-bottom: 2rem;
            width: 100%;

            .container {
                padding: 10px 50px 10px 20px;
            }
        }

        &.accordion-active {

            &:after {
                z-index: 3;
            }
            z-index: 1;

            .container {
                height: auto;
                z-index: 2;
                cursor: auto;

                > * {
                    pointer-events: auto;
                }
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 27px;
            right: 40px;
            width: 14px;
            height: 14px;
            background-image: url("../img/svg/rectangle.svg");
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            transition: .3s;
            z-index: 1;


            @media(max-width: $mobile) {
                right: 20px;
                top: 24px;
            }
        }
    }

    .submit {
        width: 25%;

        [data-component="button"] {
            display: block;

            .btn {
                display: block;
            }
        }

        @media(max-width: $mobile) {
            width: auto;

            [data-component="button"] {
                width: 160px;
            }
        }
    }
}
