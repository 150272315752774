[data-template="home-section-3"] {
    text-align: center;
    background-image: url("../img/home-section-3.png");
    background-size: cover;

    .container {
        padding: 5.2rem 2rem 4.9rem;

        @media(max-width: $mobile) {
            padding: 3rem 1rem;
        }
    }

    [data-component="text"] {
        color: $color_white;
    }
}
