[data-component="brands"] {
    display: flex;
    align-items: center;
    margin: 50px -35px;

    > * {
        /*margin: 0 35px;
        max-width: 8%;*/

        margin: 0 14.375px;
        max-width: 10.49%;
    }

    @media (max-width: 1199px) {
        flex-wrap: wrap;
        justify-content: center;
        // margin: 50px auto;

        > * {
            flex-basis: auto;
            width: 10%;
            margin: 35px;
            max-width: 100%;
        }
    }

    @media (max-width: $portrait_large) {
        margin: 3rem 0;

        > * {
            width: 15%;
        }
    }

    @media(max-width: $mobile_large) {
        margin: 3rem 0 5rem;

    }

    @media (max-width: $mobile) {

        margin: 0;

        > * {
            width: 30%;
        }
    }
}
