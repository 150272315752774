[data-template="faq-section-1"] {

    .container {
        padding:  6.375rem 2rem 3.375rem;
    }

    .inner-content,
    .inner {
        max-width: 1160px;
        margin: 0 auto;
    }
}
