[data-component="products-list"] {
    margin: 8rem -2.5rem 4rem;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;


    [data-component=image] img {
        max-height: 150px;
        //height: 100%;
        max-width: 100%;
        width: auto;
    }

    @media(max-width: $portrait_large) {
        margin: 3rem 0;

        &:not([data-widget="home-slider"]):not(.shorten):not(.products-page) {
            // display: none;
        }
    }

    @media(max-width: $mobile) {

        &.products-page {

            .figure,
            figure {
                margin-bottom: 24px;
            }
        }
    }

    .figure,
    figure {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4.25rem 3.75rem 7.375rem;
        background: $color_grey;
        height: 396px;
        transition: .3s;
        //width: 29%;
        width: calc( 100% / 3 - 2rem );
        margin: 0 1rem 0.875rem;
        overflow: hidden;


        @media(max-width: $portrait_large) {
            [data-component="image"] {
                max-width: 120px;
            }
        }

        @media(max-width: $portrait_large) {
            width: 250px;
            margin: auto;
        }

        [data-component="image"] {
            transition: .3s;
            transform-origin: top center;
            max-width: 150px;
        }

        .description {
            text-align: left;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 22px 16px 21px;
            transform: translatey(127px);
            transition: transform .4s, background-color .2s;

            .title {
                display: flex;
                align-items: center;

                .name {
                    font-size: 1.125em;
                    color: $color_text;
                    font-weight: 700;
                    line-height: 1.25;
                    width: 70%;
                }

                .isavailable {
                    text-transform: uppercase;
                    text-align: center;
                    padding: 0 3px;
                    transition: .3s;

                    .top {
                        font-size: 10px;
                        margin-bottom: 5px;
                    }
                    .bottom {
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        color: $color_yellow;
                    }
                }
            }

            .content {
                border-top: 1px solid rgba(0,0,0,0.23);
                margin-top: 21px;
                padding-top: 15px;

                .link {
                    color: $color_white;
                    font-size: 12px;
                    font-weight: 600;

                }

                [data-component="text"] {

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                }
            }

            // @media(max-width: $mobile) {
            //     transform: none;
            //
            //     .content {
            //         display: none;
            //     }
            // }
        }


        &:hover {
            [data-component="image"] {
                transform: translatey(-20%) scale(.65);
            }
            .description {
                transform: translatey(0);
                background-color: $color_yellow;

                .title {

                    .isavailable {

                        .bottom {
                            color: $color_white;
                        }
                    }
                }
            }
        }
    }

    &.shorten {

        @media(max-width: 700px) {
            display: block;
        }

        @media(max-width: $mobile) {
            .figure,
            figure {
                max-width: 300px;
                width: auto;
                height: auto;
                padding: 0 16px;
                margin-bottom: 10px;

                &:hover {
                    [data-component="image"] {
                        transform: none;
                    }
                    .description {
                        transform: none;
                        background-color: unset;

                        .title {

                            .name {
                                width: 90%;
                            }

                            .isavailable {

                                .bottom {
                                    color: $color_yellow;
                                }
                            }
                        }
                    }
                }

                [data-component="image"] {
                    width: 38px;
                    min-width: 38px;
                }

                .description {
                    position: static;
                    transform: none;

                    .title {
                        align-items: flex-start;
                        .name {
                            font-size: 18px;
                        }
                    }

                    .content {
                        display: none;
                    }
                }
            }
        }

    }
}
