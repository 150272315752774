[data-widget="image-zoom"] {

    .zoom {
        &-navigation {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 3rem;

            .plus, .minus {
                transition: .3s;
                cursor: pointer;

                &:hover {
                    opacity: .75;
                }
            }

            .plus {
                margin-right: 8px;
            }
        }

        &-in {
            transform: scale(1.35);
        }

        &-image {
            max-width: 230px;
            margin: 0 auto;
            text-align: center;

            &_disabled {

                img {
                    width: 100%;
                }

                @media(max-width: $portrait_large) {
                    .zoom-in {
                        transform: scale(1);
                    }
                }
            }

            img {
                transition: .5s;
                transform-origin: center center;
            }
        }
    }
}
