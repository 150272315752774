[data-widget="home-slider"] {
    position: relative;
    max-width: 780px;
    width: 100%;
    margin: 5rem auto;

    &:after {
        content: "";
        width: 85%;
        height: 50%;
        position: absolute;
        bottom: 3px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 30%;
        box-shadow: 0 30px 60px -5px rgba(0, 0, 0, 0.25);
        z-index: -1;

        @media(max-width: $mobile) {
            box-shadow: none;

        }
    }

    &[data-component="products-list"] {
        display: none;

        .swiper-wrapper {
            margin: 0 auto;
        }

        @media(max-width: $portrait_large) {
            display: flex;
        }

        &:after {
            display: none;
        }
    }

    @media(max-width: $mobile) {
        margin: 5rem 0 3rem;
    }

    &.popular-products  {

        .swiper {

            &-navigation {
                display: flex;
    	        justify-content: space-between;
    	        align-items: center;
                position: absolute;
                top: 50%;
                left: -13%;
                width: 126%;
                height: 0;

                @media(max-width: 1000px) {
                    left: -25px;
                    width: 107%;
                }

                @media(max-width: $mobile) {
                    left: 0; right: 0;
                    width: 100%;
                    margin: 0 auto;
                }
            }

            &-slide {

                &:nth-of-type(even) {

                    .products {

                        &-container {

                            > * {

                                &:nth-of-type(1) {
                                    background-color: $color_yellow;

                                    [data-component="text"] {
                                        color: $color_text
                                    }
                                }

                                &:nth-of-type(2) {
                                    background-color: $color_text;

                                    [data-component="text"] {
                                        color: $color_white;

                                    }
                                }
                            }
                        }
                    }
                }

                &:nth-of-type(odd) {
                    .products {

                        &-container {

                            > * {
                                &:nth-of-type(1) {
                                    background-color: $color_text;

                                    [data-component="text"] {
                                        color: $color_white;

                                    }
                                }

                                &:nth-of-type(2) {
                                    background-color: $color_yellow;

                                    [data-component="text"] {
                                        color: $color_text
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &-button {

                &-prev {
                    background-image: url('../img/svg/circleblack.svg');
                    top: 50%; left: -100px;
                }

                &-next {
                    background-image: url('../img/svg/circleblack.svg');
                    top: 50%; right: -50px;
                    transform: rotate(180deg);
                }

                &-prev, &-next {
                    border: 2px solid $color_text;


                    @media(max-width: 1000px) {
                        border: none;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    &.product-categories {

        .swiper {

            &-navigation {
                display: flex;
    	        justify-content: center;
    	        align-items: center;
                margin-top: 5rem;
            }

            &-button {

                &-prev, &-next {
                    display: block;
                    border: 2px solid $color_white;
                }

                &-prev {
                    background-image: url('../img/svg/circlewhite.svg');
                    margin-right: 3rem;
                }

                &-next {
                    background-image: url('../img/svg/circlewhite.svg');
                    transform: rotate(180deg);
                }
            }
        }
    }

	.swiper {

		&-container {
			overflow: hidden;
	        position: relative;
		}

		&-wrapper {
			display: flex;
		}

		&-slide {
			flex-shrink: 0;
			position: relative;
		}
	}

    [class*="swiper-button-"] {
        width: 60px; height: 60px;
        min-width: 60px; min-height: 60px;
        cursor: pointer;
        display: flex; align-items: center; justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background-size: 60px;;
        background-position: center center;
        background-repeat: no-repeat;
        outline: none;
        transition: .3s;

        &:hover:not(.swiper-button-disabled) {
            opacity: .65;

            @media(max-width: $laptop_small) {
                opacity: 1;
            }
        }
    }

    .swiper-button {

        &-disabled {
            opacity: .2;
        }
    }

    .products {

        &-container {
            text-align: center;

            font-size: 16px;

            [data-component="text"] {
                font-family: "Montserrat", sans-serif;
                font-size: 1.5em;
                font-weight: 800;
                text-transform: uppercase;
                line-height: 1.1;

                @media(max-width: $mobile) {
                    font-size: 1em;
                }
            }


            [data-component="image"] {
                margin-bottom: 2rem;

                img {
                    //max-width: none;
                    width: auto;
                    height: auto;
                    max-height: 160px;
                    max-width: 160px;

                    @media(max-width: $mobile) {
                        max-width: 100%;
                    }
                }
            }

            > * {
                padding: 40px;
                height: 320px;
            }
        }
    }
}
