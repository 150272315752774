[data-component="category-list"] {
    margin-bottom: 84px;

    .title {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 14px;

    }

    .list {
        font-size: 18px;
        font-weight: 800;
        text-transform: capitalize;

        > * {
            padding: 10px 0 5px;
            border-bottom: 1px solid $color_grey;

            &:last-child {
                border-bottom: 0;
            }

            > * {
                display: inline-flex;
                padding: 0 10px 0 15px;
                line-height: 3;
                color: #929292;
                transition: .3s;

                &:hover {
                    color: $color_black;
                }

                &.active {
                    color: $color_black;
                    &:before {
                        content: "";
                        transform: translatex(-30px);
                        border-left: 5px solid #ffe846;
                    }
                }
            }
        }
    }
}
