[data-template="products-section-1"] {
    padding: 7rem 5rem 0;

    @media(max-width: $laptop_small) {
        padding: 7rem 2rem 0;

        .grid.lefty {

            [data-component=products-list] {
                margin: 0;

                .figure,
                figure {
                    flex: 1 33%;
                    margin: 5px;

                    .description {
                        transform: translatey(130px);
                    }
                    &:hover {
                        .description {
                            transform: translatey(0);
                        }
                    }
                }
            }

            .left {
                margin-right: 35px;
                min-width: 240px;
                padding-right: 15px;
                padding-left: 15px;
            }

            .right {
                padding-left: 0;
            }

            [data-component="category-list"] {
                .list {
                    font-size: 16px;

                    > * {
                        > * {
                            line-height: 2.5;
                        }
                    }

                }
            }

        }
    }





    @media(max-width: 800px) {
        .grid.lefty {
            .left {
                min-width: 280px;
                margin-right: 15px;
            }
            [data-component=products-list] {
                margin: 0;

                .figure,
                figure {
                    width: auto;
                    flex: 1 45%;

                    .description {
                        //transform: translatey(150px);
                    }

                    [data-component="image"] {

                    }
                }
            }
        }
    }

    @media(max-width: $portrait_large) {

        .grid.lefty {

            .left {
                margin-right: 10px;
            }

            [data-component=products-list] {

                .figure,
                figure {

                    .description {
                        transform: translatey(140px);
                    }

                    &:hover {
                        .description {
                            transform: translatey(0);
                        }
                    }
                }
            }

            .right {
                padding-left: 0;
            }
        }
    }

    @media(max-width: 700px) {
        .grid.lefty {

            [data-component=products-list] {
                display: block;

                .figure,
                figure {
                    //width: 100%;

                    .description {
                        transform: translatey(125px);

                        .title {
                            .name {
                                width: 75%;
                                padding-right: 50px;
                            }

                        }

                        .content {
                            display: block;
                        }
                    }
                    &:hover {
                        .description {
                            background-color: $color_yellow;
                            transform: translatey(0);
                        }
                    }
                }

                &.shorten {
                    display: block;

                    .figure,
                    figure {
                        max-width: unset;
                        width: auto;
                        height: auto;
                        padding: 0 16px;
                        margin-bottom: 10px;

                        [data-component="image"] {
                            width: 38px;
                            min-width: 38px;
                        }

                        .description {
                            position: static;
                            transform: none;

                            .title {
                                align-items: flex-start;

                                .name {
                                    padding-right: 10px;
                                    font-size: 18px;
                                }
                            }

                            .content {
                                display: none;
                            }
                        }

                        &:hover {
                            .description {
                                background-color: unset;
                                transform: translatey(0);
                            }
                        }
                    }
                }
            }
        }
    }


    .inner {
        max-width: 1160px;
        margin: 0 auto;

        [data-component=products-list] {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .grid-navigation {
        margin-bottom: 2rem;
        display: none;

        @media(max-width: 700px) {
            display: block;
        }

        > * {
            font-size: 18px;
            font-weight: 600;
            display: inline-block;
            background-color: #ADADAD;
            color: $color_white;
            text-transform: uppercase;
            padding: 10px;
            text-align: center;

            &.active {
                background-color: #312D3B;
            }

            &:first-of-type {
                margin-right: 8px;
            }

        }
    }

    @media(max-width: 700px) {
        padding: 5rem 24px;

        .grid.lefty {
            flex-direction: column;
            padding-top: 2rem;

            .left, .right {
                width: 100%;
                margin: 0;
            }

            .left {
                order: 2;
                padding-left: 0;
                padding-right: 0;
                border-right: none;
            }

            .right {
                order: 1;
            }

            .category-block {
                position: absolute;
                top: 85px;
                left: 0;
                background-color: #fff;
                height: 0;
                width: 100%;
                z-index: 10;
                overflow: hidden;

                [data-component="category-list"] {
                    margin-bottom: 30px;
                    padding: 0 15px;
                }
            }

            &.product-menu_active {

                .product-menu {
                    position: relative;

                    span {
                        z-index: 2;
                        position: relative;
                    }


                    &:before {
                        content: "";
                        position: absolute;
                        left: -24px; top: 0;
                        width: 200vw;
                        height: 100%;
                        background-color: #312d3b;
                        z-index: 1;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        left: -24px; top: 0;
                        width: 200%;
                        height: 100%;
                        background-color: #312d3b;
                        z-index: 1;
                    }
                }

                .category-block {
                    height: auto;
                }
            }

            [data-component=advert-area] {

                border-top: 2px solid #f5f5f5;
                border-bottom: none;
                padding: 2rem 24px 2rem;

                &:first-child {
                    margin-top: 2rem;
                }
            }

            [data-component=products-list] {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 20px;

                .figure,
                figure {

                }
            }
        }
    }

    @media(max-width: $mobile) {
        .grid.lefty {
            [data-component=products-list] {
                display: block;
            }
        }

    }
}

@media(min-width: 701px) {
    [data-template="products-section-1"] {

        .grid {

            &.lefty {

                .left {
                    max-width: 280px;
                }
            }
        }
    }
}