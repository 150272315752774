[data-component="websearch"] {
    background-color: $color_grey;
    display: flex;
    align-items: center;
    padding: .75rem 1rem;
    max-width: 230px;
    width: 100%;

    @media(max-width: 1299px) {
        max-width: 400px;
    }

    @media(max-width: $mobile) {
        max-width: 100%;
    }


    &:before {
        content: "";
        min-width: 30px; min-height: 30px;
        background-image: url('../img/svg/search.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 1rem;
    }

    input {
        border: none;
        outline: none;
        background: none;
        font-family: 'Montserrat', sans-serif;
        font-size: .875em;

        &::-webkit-input-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 1em;
        }

        &::-moz-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 1em;
        }

        &:-ms-input-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 1em;
        }

        &:-moz-placeholder {
            font-family: 'Montserrat', sans-serif;
            font-size: 1em;
        }
    }

}
