[data-component="weblogo"] {
    font-size: 22px;
    display: flex;
    align-items: center;
    margin-bottom: -2.25rem;
    margin-right: 5.438rem;

    a {

        img {
            max-width: 100%;
            width: 100%;
            height: 100%;
        }
    }

    @media(max-width: 1299px) {
        width: 130px;
        margin: -1rem 0 0 0;
    }
}
