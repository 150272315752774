[data-template="brand-section-3"] {
    text-align: center;

    @media(max-width: $mobile) {

        [data-widget="home-slider"] {
            margin: 5rem 0;
        }
    }

    .container {
        padding: 5rem;

        @media(max-width: $mobile) {
            padding: 5rem 0;
        }
    }

    .inner {
        max-width: 780px;
        margin: 0 auto;
    }
}
