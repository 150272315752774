[data-component="filter"] {
    position: absolute;
    right: 0;
    top: -85px;
    padding-right: 34px;

    @media(max-width: $mobile) {
        top: -65px;
    }


    &:after {
        content: "";
        position: absolute;
        top: 33px;
        right: 0;
        width: 14px;
        height: 14px;
        background-image: url(../img/svg/rectangle.svg);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: .3s;
        z-index: 1;
    }

    .label {
        font-size: 16px;
        line-height: 19px;
        font-weight: 200;
        text-transform: uppercase;
        margin-bottom: 6px;

        @media(max-width: $mobile) {
            margin-bottom: 3px;
        }

    }

    .title {
        font-size: 24px;
        line-height: 29px;
        font-weight: 800;
        cursor: pointer;
    }

    .selection {
        border: 1px solid #979797;
        background-color: $color_white;
        position: absolute;
        top: calc(100% + 20px);
        right: -23px;
        z-index: 15;
        padding: 30px 30px 15px 30px;
        width: 210px;
        transform: translatey(15px);
        opacity: 0;
        visibility: hidden;
        transition: .4s;

        &:after {
            content: "";
            position: absolute;
            top: -9px;
            right: 18px;
            transform: rotate(-135deg);
            border: 9px solid;
            border-color: transparent #fff #fff transparent;
            box-shadow: 1px 1px 0 #979797;


        }

        @media(max-width: $mobile) {
            right: -15px;

            &:after {
                top: -9px;
                right: 12px;
            }
        }
    }

    &.active  {

        .selection {
            opacity: 1;
            visibility: visible;
            transform: translatey(0);
        }
    }

    [data-component=category-list] {
        margin-bottom: 0;

        .list {
            font-size: 22px;
            font-weight: 300;
            color: #5E5E5E;

            > * {
                padding: 0;
                border-bottom: 1px solid $color_grey;
                color: inherit;

                &:last-child {
                    border-bottom: 0;
                }

                > * {
                    color: inherit;

                    &.active {
                        font-weight: 800
                    }
                }
            }
        }
    }
}
