[data-component="social"] {
    display: flex;
    justify-content: center;
    margin: 0 -10px;

    > * {
        width: 35px;
        margin: 0 10px;
        flex-basis: auto;
        transition: .3s;

        &:hover {
            opacity: .75;
        }
    }
}
