[data-component="material"] {

    margin-bottom: 5.313rem;

    @media(max-width: $mobile) {
        margin-bottom: 45px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    [data-component="text"] {
        h4 {
            font-weight: 800;
        }
    }

    .inner-content {
        font-family: "Poppins", sans-serif;

        > * {
            display: flex;
            align-items: center;
            font-size: 1.125em;
            line-height: 1.6;
            transition: .3s;

            &:hover {
                opacity: .75;
            }

            &:before {
                content: "";
                display: block;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 2rem;
            }

            @media(max-width: $mobile) {
                align-items: flex-start;


                margin: .75rem .25rem 2rem;

            }
        }

        &.pdf {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -.25rem;

            > * {
                width: 48%;
                margin: .75rem .25rem;
                flex-basis: auto;

                &:before {
                    background-image: url('../img/pdf.png');
                    min-width: 35px;
                    min-height: 40px;
                    width: 35px;
                    height: 40px;
                }
            }

            @media(max-width: $mobile) {
                display: block;
                margin: 0;

                > * {
                    width: auto;
                    margin: 0 0 2rem 0;
                }
            }
        }

        &.img {

            > * {
                margin: .75rem 0;

                &:before {
                    background-image: url('../img/picture.png');
                    min-width: 35px;
                    min-height: 40px;
                    width: 35px;
                    height: 40px;
                }
            }
        }

        &.data {
            > * {
                margin: .75rem 0;

                &:before {
                    background-image: url('../img/card.png');
                    min-width: 35px;
                    min-height: 40px;
                    width: 35px;
                    height: 40px;
                }
            }
        }
    }
}
